<template>
  <ValidationProvider
    :name="to.label"
    :rules="field.rules"
    :class="`field field--${to.layout}`"
    tag="div"
    v-slot="{ errors }"
  >
    <label
      :for="field.key"
      v-if="to.label"
      :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']"
    >{{ to.label }}</label>

    <v-select
      v-model="model[field.key]"
      :options="divisions"
      @search:focus="toggleFocus"
      @search:blur="toggleFocus"
      :reduce="item => item.id"
      label="name"
    />

    <div :class="['field__underline', focused ? 'field__underline--active' : '']"></div>
    <div class="field__error">{{ errors[0] }}</div>
    <div class="btn-link" @click="toggleForm">{{newDivisionText}}</div>
    <vue-toggle-slide :active="addNewDivision" class="pt-24">
      <div class="Card Card--limited">
        <DivisionForm @completed="toggleForm" :disabled="true" />
      </div>
    </vue-toggle-slide>
  </ValidationProvider>
</template>
<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from "vue-formly-bootstrap/src/fields/baseField";
import { mapState } from "vuex";

import DivisionForm from "@/components/Forms/DivisionForm";

export default {
  mixins: [baseField],
  name: "SelectField",
  components: {
    DivisionForm
  },
  data() {
    return {
      addNewDivision: false,
      focused: false
    };
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused;
    },
    toggleForm() {
      this.addNewDivision = !this.addNewDivision;
    }
  },
  computed: {
    ...mapState({
      divisions: state => state.user.divisions
    }),
    newDivisionText() {
      return this.addNewDivision ? "Hide form" : "Add new division";
    }
  }
};
</script>
