<template>
  <ValidationProvider
    :name="to.label"
    :rules="field.rules"
    :class="`field field--${to.layout}`"
    tag="div"
    v-slot="{ errors }"
  >
    <label
      :for="field.key"
      v-if="to.label"
      :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']"
    >{{ to.label }}</label>

    <v-select
      v-model="model[field.key]"
      :autoscroll="true"
      :options="to.options"
      @search:focus="toggleFocus"
      :multiple="to.multiple"
      @search:blur="toggleFocus"
      :reduce="item => item.value"
      :label="to.optionLabel || 'label'"
      v-if="typeof(to.options[0]) == 'object'"
    />

    <v-select
      v-model="model[field.key]"
      :options="to.options"
      :autoscroll="true"
      @search:focus="toggleFocus"
      :multiple="to.multiple"
      :label="to.optionLabel || 'label'"
      @search:blur="toggleFocus"
      v-else
    />

    <div :class="['field__underline', focused ? 'field__underline--active' : '']"></div>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>
<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from "vue-formly-bootstrap/src/fields/baseField";
export default {
  mixins: [baseField],
  name: "SelectField",
  data() {
    return {
      focused: false
    };
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused;
    }
  }
};
</script>
