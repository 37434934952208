<template lang="html">
  <section id="submission">
    <Header></Header>
    <slot />
  </section>
</template>

<script>
import Header from '@/partials/Header/Unregistered';
export default {
  name: 'AppLayout',
  components: {
    Header,
  }
}
</script>
