<template lang="html">
  <div :class="`icon icon--${icon}`">
    <inline-svg :src="require(`@/assets/icon-${icon}.svg`)" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    }
  }
}
</script>
