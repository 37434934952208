const NotificationsMixin = {
  methods: {
    handleNotification(notification, permanent) {
      if (permanent) {
        this.$store.commit('notifications/addNotification', notification)
      }
      this.$notify({
        group: 'notifications',
        data: notification,
      })
    },
  },
}

export default NotificationsMixin
