<template>
  <ValidationProvider :name="to.label" :rules="field.rules" :class="`field field--${to.layout}`" tag="div" v-slot="{ errors }">
    <label :for="field.key" v-if='to.label' :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']">{{ to.label }}</label>

    <v-select
      v-model="model[field.key]"
      :options="events"
      @search:focus="toggleFocus"
      :multiple="to.multiple"
      @search:blur="toggleFocus"
      :reduce="item => item.id"
      label="name" />

    <div :class="['field__underline', focused ? 'field__underline--active' : '']"></div>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>
<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from 'vue-formly-bootstrap/src/fields/baseField';
import {mapState} from 'vuex'
export default {
  mixins: [baseField],
  name: 'SelectField',
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused
    },
  },
  computed: {
    ...mapState({
      events: state => state.events.all
    }),
  }
}
</script>
