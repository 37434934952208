<template lang="html">
  <notifications
    group="notifications"
    position="top right"
    :duration="4500"
    width="400px"
  >
    <template slot="body" slot-scope="props">
      <div class="Notification Notification--new Notification--instance">
        <div class="Notification__image">
          <profile-pic
            :size="50"
            :src="props.item.data.sender.photo_url"
            v-if="props.item.data.sender"
          ></profile-pic>
          <profile-pic :size="50" v-else></profile-pic>
        </div>
        <div class="Notification__content">
          <div class="Notification__header">
            <div class="dot dot--blue"></div>
            <div class="Notification__user text-md text-white text-bold">
              {{
                props.item.data.sender
                  ? props.item.data.sender.name
                  : props.item.data.title
                  ? props.item.data.title
                  : 'New notification'
              }}
            </div>
          </div>
          <div class="Notification__body">
            {{ props.item.data.body }}
          </div>
          <div class="Notification__footer">
            <div
              class="Notification__time text-bold text-sm"
              v-if="props.item.data.created_at"
            >
              {{ props.item.data.created_at | moment('from', 'now') }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
  import Time from '@/helpers/TimeHelper'
  export default {
    name: 'PopupNotification',
    computed: {
      createdTime() {
        return Time.convertUtcToLocal(this.props.item.data.created_at)
      },
    },
  }
</script>
