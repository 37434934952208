const OnboardingRoutes = [
  {
    path: '/payment-information',
    name: 'payment-info',
    component: () => import('@/views/Onboarding/PaymentInformation'),
    meta: {
      auth: true,
      layout: 'unregistered',
    },
  },
]

export default OnboardingRoutes
