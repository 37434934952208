<template lang="html">
  <div class="Timer" v-if="timer">
    <div class="Timer__wrapper" v-if="timer && timer.break_status == 'active'">
      <div class="Timer__action">
        <div class="btn btn--disabled">Break Time</div>
      </div>
      <div class="Clock Clock--break">
        <svg-icon icon="clock"></svg-icon>
        <div class="Clock__content">
          <h1 class="title-6 text-uppercase">Break Time</h1>
          <div class="Clock__timer" v-if="timer">
            <div class="title-3" v-if="timer.remaining_break_duration > 0">
              {{ timer.remaining_break_duration | moment('mm:ss') }}
            </div>
            <div class="title-3" v-else>0:00</div>
            <Dropdown
              :items="breakModifications"
              prop="label"
              keyProp="value"
              @dropdown-item-click="changeBreakDuration"
              align="right" />
          </div>
          <div class="title-3" v-else>-:--</div>
        </div>
      </div>
    </div>

    <div class="Timer__wrapper" v-else>
      <div class="Timer__action">
        <div
          class="btn btn--green"
          @click="startTimer"
          v-show="showResumeButton">
          {{ btnText }}
        </div>
        <div
          class="btn btn--outlined"
          @click="stopTimer"
          v-show="showPauseButton">
          Pause Game
        </div>
      </div>
      <div class="Clock" v-if="!gameCompleted">
        <svg-icon icon="clock"></svg-icon>
        <div class="Clock__content">
          <h1 class="title-6 text-uppercase text-light">Game Clock</h1>
          <div class="Clock__timer" v-if="timer">
            <div class="title-3" v-if="timer.remaining_duration > 0">
              {{ timer.remaining_duration | moment('mm:ss') }}
            </div>
            <div class="title-3" v-else>0:00</div>
            <Dropdown
              :items="timerModifications"
              prop="label"
              keyProp="value"
              @dropdown-item-click="changeTimerDuration"
              align="right" />
          </div>
          <div class="title-3" v-else>-:--</div>
        </div>
      </div>
      <div class="Clock Clock--break" v-else>
        <svg-icon icon="clock"></svg-icon>
        <div class="Clock__content">
          <div class="Clock__timer">
            <h1 class="title-6 text-uppercase text-light mr-16">
              Game Finished
            </h1>
            <Dropdown
              :items="[{ label: 'Reset timer', value: 'reset' }]"
              prop="label"
              keyProp="value"
              @dropdown-item-click="changeTimerDuration"
              align="right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Time from '@/helpers/TimeHelper';
  import api from '@/api/index';
  import config from '@/config/events_config';
  import bell from '@/assets/bell-ding.mp3';

  export default {
    name: 'Timer',
    props: {
      game: {
        type: Object,
        required: true,
      },
    },
    data: function () {
      return {
        timer: null,
        timerModifications: [
          ...config.timer_modification,
          { label: 'Reset timer', value: 'reset' },
        ],
        breakModifications: [{ label: 'Finish break', value: 'finish' }],
        clockRunBefore: false,
        clockIsRunning: false,
        breakClockIsRunning: false,
        timerInterval: null,
      };
    },

    mounted() {
      this.getTimer();
      this.$echo
        .private(`timers.${this.game.timer.id}`)
        .listen('.timer.updated', (e) => {
          this.timer = e.timer;
          this.initializeTimer();
        });
    },

    destroyed() {
      this.$echo.leave(`timers.${this.game.timer.id}`);
      if (this.timer.status !== 'completed') {
        api.http.put(`timers/${this.game.timer.id}`, this.timer).then(() => {
          this.clearIntervalFunction();
        });
      }
    },

    methods: {
      getTimer() {
        api.http.get(`timers/${this.game.timer.id}`).then((res) => {
          this.timer = res.body;
          this.initializeTimer();
        });
      },

      updateTimer() {
        let data = this.timer;
        api.http.put(`timers/${this.game.timer.id}`, data);
      },

      initializeTimer() {
        switch (this.timer.status) {
          case 'running':
            {
              this.clockRunBefore = true;
              let differance = Time.differenceBetweenUtcDateAndNowInSeconds(
                this.timer.start_time,
              );
              this.timer.remaining_duration =
                this.timer.remaining_duration - differance;
              if (!this.clockIsRunning) this.setIntervalFunction();
            }
            break;

          case 'paused':
            this.clockRunBefore = true;
            if (this.clockIsRunning) this.stopTimer();
            break;

          case 'completed':
            {
              if (this.timer.break_status == 'active') {
                this.clockRunBefore = true;
                let differance = Time.differenceBetweenUtcDateAndNowInSeconds(
                  this.timer.break_started_at,
                );
                this.timer.remaining_break_duration =
                  this.timer.break_duration - differance;
                if (!this.breakClockIsRunning) this.setBreakIntervalFunction();
              }
            }
            break;
        }
      },

      startTimer() {
        if (!this.clockIsRunning) {
          this.timer.start_time = Time.getFormattedCurrentDateUTC();
          this.timer.status = 'running';
          this.setIntervalFunction();
          this.updateTimer();
        }
      },

      changeTimerDuration(payload) {
        if (payload.value === 'reset') {
          this.timer.remaining_duration = this.timer.duration;
          this.timer.status = null;
          this.timer.start_time = null;
          this.timer.paused_at = null;
          this.break_status = 'uninitiated';
          this.timer.remaining_break_duration = this.timer.break_duration;
          this.updateTimer();
        } else {
          let newVal = this.timer.remaining_duration + payload.value;
          this.timer.remaining_duration = newVal <= 15 ? 15 : newVal;
          this.updateTimer();
        }
      },

      changeBreakDuration(payload) {
        if (payload.value == 'finish') {
          this.timer.remaining_break_duration = 0;
          this.timer.break_status = 'finished';
          this.updateTimer();
        }
      },

      stopTimer() {
        if (this.clockIsRunning) {
          this.clearIntervalFunction();
          this.timer.paused_at = Time.getFormattedCurrentDateUTC();
          this.timer.status = 'paused';
          this.updateTimer();
        }
      },

      finishTimer() {
        this.clearIntervalFunction();
        this.playSound();
        this.timer.status = 'completed';
        this.timer.break_status = 'active';
        this.timer.break_started_at = Time.getFormattedCurrentDateUTC();
        this.$store.dispatch('events/GET_EVENT', this.game.event_id);
        this.updateTimer();
        this.initializeTimer();
      },

      playSound() {
        new Audio(bell).play();
      },

      clearIntervalFunction() {
        clearInterval(this.timerInterval);
        this.timer.start_time = null;
        this.clockIsRunning = false;
        this.timerInterval = null;
      },

      clearBreakIntervalFunction() {
        clearInterval(this.timerInterval);
        this.breakClockIsRunning = false;
        this.timer.break_status = 'completed';
        this.timerInterval = null;
      },

      setIntervalFunction() {
        this.clockIsRunning = true;
        this.timer.paused_at = null;
        this.timerInterval = setInterval(() => {
          if (this.timer.remaining_duration > 0) {
            this.timer.remaining_duration--;
          } else {
            this.timer.remaining_duration = 0;
            this.finishTimer();
          }
        }, 1000);
      },

      setBreakIntervalFunction() {
        this.breakClockIsRunning = true;
        this.timerInterval = setInterval(() => {
          if (this.timer.remaining_break_duration > 0) {
            this.timer.remaining_break_duration--;
          } else {
            this.timer.remaining_break_duration = 0;
            this.clearBreakIntervalFunction();
          }
        }, 1000);
      },
    },

    computed: {
      btnText() {
        return this.timer !== null &&
          this.timer.status == null &&
          !this.clockRunBefore
          ? 'Start Game'
          : 'Resume Game';
      },
      showResumeButton() {
        return (
          this.timer !== null &&
          !this.clockIsRunning &&
          this.timer.remaining_duration > 0 &&
          this.timer.status !== 'running'
        );
      },
      showPauseButton() {
        return (
          this.timer !== null &&
          (this.clockIsRunning || this.timer.status == 'running')
        );
      },
      gameFinished() {
        return this.timer !== null && !this.timer.remaining_duration > 0;
      },
      gameCompleted() {
        return (
          (this.timer !== null) & (this.timer.break_status == 'completed') &&
          this.timer.status == 'completed'
        );
      },
    },
  };
</script>

<style lang="scss">
  .Timer {
    flex: 1;
    &__wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
      flex: 1;
      &__actions {
        margin-right: 30px;
      }
    }
  }

  .Clock {
    display: flex;
    user-select: none;
    align-items: center;
    background-color: white; //color(bg, 5)
    padding: 10px 28px 10px 15px;
    border-radius: 5px;
    margin-left: auto;
    &__content {
      margin-left: 15px;
    }
    &__timer {
      display: flex;
      align-items: center;
      .title-3 {
        margin: 0 10px 0 0;
      }
    }

    .dropdown {
      z-index: 9;
    }

    &--break {
      background-color: #3d3f43; //color(bg, 5)
      color: white;
      svg g {
        stroke: white !important;
      }

      .title-6,
      .title-3 {
        color: white;
      }
    }
  }
</style>
