<template>
  <ValidationProvider :name="to.label" :rules="field.rules" :class="`field field--${to.layout}`" tag="div" v-slot="{ errors }">
      <label :for="field.key" v-if='to.label' :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']">{{ to.label }}</label>
      <textarea-autosize rows="1" v-model="model[field.key]" :min-height="34" :max-height="100" class="input input--textarea" @focus.native="toggleFocus" @blur.native="toggleFocus" :id="field.key" />
      <div :class="['field__underline', focused ? 'field__underline--active' : '']"></div>
      <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from 'vue-formly-bootstrap/src/fields/baseField';
export default {
  mixins: [baseField],
  name: 'Textarea',
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused
    },
  }
}
</script>
