<template lang="html">
  <nav
    :class="[
      'Navigation',
      isMenuActive ? 'Navigation--active' : '',
      isAnimated ? 'Navigation--animated' : '',
    ]"
  >
    <div class="Navigation__top">
      <div class="Navigation__user">
        <User reversed></User>
      </div>
      <NavigationButton
        to="/dashboard"
        name="Dashboard"
        icon="pie-chart"
        v-if="userNot('player')"
      />
      <NavigationButton to="/events" name="Events" icon="calendar" />
      <NavigationButton
        to="/players"
        name="Players"
        icon="people"
        v-if="userNot('player')"
      />
      <!-- <NavigationButton to="/games" name="Games" icon="hoop" /> -->
      <NavigationButton
        to="/profile"
        name="Player Profile"
        icon="people"
        v-if="userIs('player')"
      />
    </div>
    <div class="Navigation__bottom">
      <router-link to="/settings" class="Navigation__link"
        >Settings</router-link
      >
      <router-link
        to="/subscription"
        class="Navigation__link"
        v-if="userIs('admin')"
        >Subscription</router-link
      >
      <router-link
        to="/invite-selectors"
        class="Navigation__link"
        v-if="userIs('admin')"
        >Selectors</router-link
      >
      <router-link
        to="/invitations"
        class="Navigation__link"
        v-if="userNot('player')"
        >Teams & Invitations</router-link
      >
      <!-- <router-link to="/help" class="Navigation__link">Help</router-link> -->
      <div @click="handleLogOut" class="Navigation__link clickable">
        Log out
      </div>
    </div>
  </nav>
</template>

<script>
  import NavigationButton from '@/partials/Navigation/Button';
  import User from '@/components/User';
  export default {
    name: 'Navigation',
    components: {
      NavigationButton,
      User,
    },
    props: {
      isMenuActive: {
        type: Boolean,
        required: true,
      },
    },
    data: function () {
      return {
        isAnimated: false,
      };
    },
    mounted() {
      this.assessAnimation();
      window.addEventListener('resize', this.assessAnimation);
    },
    destroyed() {
      window.removeEventListener('resize', this.assessAnimation);
    },
    methods: {
      assessAnimation() {
        if (window.innerWidth < 768) {
          this.$nextTick(() => {
            this.isAnimated = true;
          });
        } else {
          this.$nextTick(() => {
            this.isAnimated = false;
          });
        }
      },
      handleLogOut() {
        this.$store.dispatch('AUTH_LOGOUT');
      },
    },
  };
</script>
