const ErrorHelper = {
  createErrorMessage(err) {
    if (err.body.hasOwnProperty("errors")) {
      let errorMessage = ''
      for (let error in err.body.errors) {
        errorMessage += `${err.body.errors[error][0]} `
      }
      return this.prepareObjectToReturn(err.body.errors, errorMessage)
    } else if (err.body.hasOwnProperty("message")) {
      return this.prepareObjectToReturn(null, err.body.message)
    } else if (err.body.hasOwnProperty('error')) {
      return this.prepareObjectToReturn(null, err.body.error == 'invalid-credentials' ? 'Invalid credentials, try again' : err.body.error)
    }
  },
  prepareObjectToReturn(object, string) {
    return {
      object: object,
      string: string,
    }
  }
}


export default ErrorHelper
