import Vue from 'vue'
import api from '@/api/index'
import ErrorHelper from '@/helpers/ErrorHelper'
import Auth from '@/helpers/AuthHelper'

const state = {
  all: null,
  current: null,
  status: {
    all: '',
  },
}
// getters
const getters = {
  getPlayer: (state) => (id) => {
    if (Array.isArray(state.all) && state.all.length > 0) {
      let player = state.all.filter((item) => item.id == id)[0]
      return player ? player : false
    } else {
      return null
    }
  },
}

// actions
const actions = {
  GET_PLAYERS(context) {
    api.http.get('players').then((res) => {
      if (res.ok) {
        context.commit('setPlayers', res.body)
        context.commit('updateAllStatus', 'success')
      }
    })
  },
  GET_PLAYER(context, id) {
    return new Promise((resolve, reject) => {
      api.http
        .get(`players/${id}`)
        .then((res) => {
          if (res.ok) {
            context.commit('setPlayer', res.body)
          } else {
            context.commit('setPlayer', false)
          }
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  },

  CREATE_PLAYER(context, payload) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Creating profile...' },
        { root: true },
      )
      let config = Auth.prepareRegisterData(payload)
      return new Promise((resolve, reject) => {
        api.http
          .post(`players`, config.content, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            let data = {
              event: payload.events,
              player: res.body.id,
            }
            context.dispatch('events/ADD_PLAYER_TO_EVENT', data, { root: true })
            context.dispatch('GET_PLAYERS')
            Vue.notify({
              group: 'general',
              title: 'Profile created succesfully!',
            })
            resolve(res)
          })
          .catch((err) => {
            let errorMessage = ErrorHelper.createErrorMessage(err)
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            context.commit('setPlayer', false)
            Vue.notify({
              group: 'general',
              title: 'Profile creation failed!',
              text: errorMessage.string,
              style: 'error',
            })
            reject(err)
          })
      })
    }
  },

  UPDATE_PLAYER(context, payload) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating profile...' },
        { root: true },
      )
      var formData = new FormData()
      for (var key in payload.data) {
        formData.append(key, payload.data[key])
      }
      formData.append('_method', 'put')
      api.http
        .post(`players/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          context.commit('setPlayer', res.body)
          context.commit('updatePlayer', res.body)
          Vue.notify({
            group: 'general',
            title: 'Profile updated succesfully!',
          })
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          context.commit('setPlayer', false)
          Vue.notify({
            group: 'general',
            title: 'Profile update failed!',
            style: 'error',
          })
        })
    }
  },

  UPDATE_SUBMISSION(context, submission) {
    api.http.put(`submissions/${submission.id}`, submission).catch(() => {
      Vue.notify({
        group: 'general',
        title: "Application couldn't be updated!",
      })
    })
  },

  REMOVE_SUBMISSION(context, submission) {
    context.dispatch(
      'status/TOGGLE_LOADER',
      { visible: true, status: "Removing player's profile..." },
      { root: true },
    )
    let eventid = submission.event_id
    api.http
      .post(`submissions/remove/${submission.id}`, submission)
      .then(() => {
        context.dispatch('GET_PLAYERS')
        context.dispatch('events/GET_EVENT', eventid, {
          root: true,
        })
        context.dispatch(
          'status/TOGGLE_LOADER',
          { visible: false },
          { root: true },
        )
        Vue.notify({
          group: 'general',
          title: 'Submission removed successfully!',
        })
      })
      .catch(() => {
        context.dispatch(
          'status/TOGGLE_LOADER',
          { visible: false },
          { root: true },
        )
        Vue.notify({
          group: 'general',
          title: "Submission couldn't be removed!",
        })
      })
  },

  CREATE_NOTE(context, payload) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Saving note...' },
        { root: true },
      )
      return new Promise((resolve, reject) => {
        api.http
          .post(`players/${payload.player}/notes`, payload)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            Vue.notify({
              group: 'general',
              title: 'Note saved!',
            })
            context.dispatch('GET_PLAYER', payload.player)
            resolve(res)
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            Vue.notify({
              group: 'general',
              title: 'Note saving failed!',
              style: 'error',
            })
            reject(err)
          })
      })
    }
  },

  UPDATE_NOTE(context, payload) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating note...' },
        { root: true },
      )
      return new Promise((resolve, reject) => {
        api.http
          .put(`players/${payload.player}/notes/${payload.id}`, payload)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            context.dispatch('GET_PLAYER', payload.player)
            Vue.notify({
              group: 'general',
              title: 'Note updated!',
            })
            resolve(res)
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            Vue.notify({
              group: 'general',
              title: 'Note update failed!',
              style: 'error',
            })
            reject(err)
          })
      })
    }
  },
  REMOVE_NOTE(context, payload) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Removing note...' },
        { root: true },
      )
      return new Promise((resolve, reject) => {
        api.http
          .delete(`players/${payload.player}/notes/${payload.id}`)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            context.dispatch('GET_PLAYER', payload.player)
            Vue.notify({
              group: 'general',
              title: 'Note removed!',
            })
            resolve(res)
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            Vue.notify({
              group: 'general',
              title: "Couldn't remove the note!",
              style: 'error',
            })
            reject(err)
          })
      })
    }
  },

  GET_RATINGS(context, payload) {
    return new Promise((resolve, reject) => {
      api.http
        .get(`players/${payload.id}/ratings`, payload)
        .then((res) => {
          context.dispatch('GET_PLAYER', payload.player)
          resolve(res)
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Rating failed!',
            style: 'error',
          })
          reject(err)
        })
    })
  },

  RATE_PLAYER(context, payload) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Saving rating...' },
        { root: true },
      )
      return new Promise((resolve, reject) => {
        api.http
          .post(`players/${payload.player}/ratings`, payload)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            Vue.notify({
              group: 'general',
              title: 'Rating added!',
            })
            context.dispatch('GET_PLAYER', payload.player)
            context.commit('setUserRatings', payload, {
              root: true,
            })
            resolve(res)
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            )
            Vue.notify({
              group: 'general',
              title: 'Rating failed!',
              style: 'error',
            })
            reject(err)
          })
      })
    }
  },
}

// mutations
const mutations = {
  setPlayers(state, payload) {
    state.all = payload
  },
  setPlayer(state, payload) {
    state.current = payload
  },
  updatePlayer(state, payload) {
    let index = state.all.findIndex((x) => x.id == payload.id)
    state.all[index] = payload
  },
  updateAllStatus(state, status) {
    state.status.all = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
