<template lang="html">
  <ValidationProvider
    :name="field.key"
    :rules="field.rules"
    :class="`field field--${to.layout}`"
    tag="div"
    v-slot="{ errors }"
  >
    <label
      :for="field.key"
      v-if="to.label"
      class="field__label field__label--normal"
      >{{ to.label }}</label
    >
    <div class="rate-input__wrapper">
      <label class="rate-input" v-for="i in to.count" :key="i">
        <svg-icon
          icon="star"
          :class="parseInt(model[field.key]) >= i ? 'filled' : ''"
        ></svg-icon>
        <input
          type="radio"
          :id="`${field.key}-${i}`"
          :name="field.key"
          :value="i"
          v-model="model[field.key]"
        />
      </label>
    </div>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
  //not required but this baseField has a lot of useful stuff already in it, check it out
  import baseField from 'vue-formly-bootstrap/src/fields/baseField'
  export default {
    mixins: [baseField],
    name: 'RateField',
    data() {
      return {
        focused: false,
      }
    },
    methods: {
      toggleFocus() {
        this.focused = !this.focused
      },
    },
  }
</script>
