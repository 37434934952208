<template lang="html">
  <vue-toggle-slide :active="showBox">
    <div class="InformationMessage">
      <svg-icon :icon="icon" v-show="icon"></svg-icon>
      <div class="InformationMessage__content">
        <slot></slot>
      </div>
      <div class="InformationMessage__close clickable" @click="closeBox">
        <svg-icon icon="x"></svg-icon>
      </div>
    </div>
  </vue-toggle-slide>
</template>

<script>
export default {
  name: "InformationMessage",
  props: {
    condition: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: function () {
    return {
      isVisible: true,
    }
  },
  methods: {
    closeBox() {
      this.isVisible = false
    }
  },
  computed: {
    showBox() {
      return this.condition ? (this.condition == true && this.isVisible == true) : false
    }
  }
}
</script>

<style lang="scss">
@import '../styles/abstracts/include';
.InformationMessage {
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 25px;
  background-color: color(bg, 5);
  border-radius: 5px;

  .icon {
    margin-right: 15px;
  }

  &__content {
    @include font-weight(bold);
    width: calc(100% - 25px);
  }

  &__close {

    width: 25px;
  }
}
</style>
