<template lang="html">
  <router-link
    :to="to"
    class="NavigationButton"
    active-class="NavigationButton--active"
  >
    <div class="NavigationButton__icon">
      <svg-icon :icon="icon" />
    </div>
    <div class="NavigationButton__title">
      {{ this.name }}
      <div class="NavigationButton__underline"></div>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'NavigationButton',
    props: {
      icon: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      to: {
        type: String,
        required: true,
      },
    },
  }
</script>
