import Vue from 'vue';
import api from '@/api/index';

const state = {
  status: false,
  pending: [],
  user: {
    status: true,
    pending: [],
  },
};
// getters
const getters = {
  totalPending: (state) => {
    return state.pending.length;
  },
};

// actions
const actions = {
  GET_INVITATIONS(context, data) {
    context.commit('updateStatus', false);
    api.http.get(`teams/${data.team}/invitations`).then((res) => {
      context.commit('setPendingInvitations', res.body);
      context.commit('updateStatus', true);
    });
  },

  SEND_INVITATION(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Sending invitation...' },
        { root: true },
      );
      api.http
        .post(`teams/${data.team}/invitations`, data)
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          if (res.ok) {
            context.commit('addInvitation', res.body);
            Vue.notify({
              group: 'general',
              title: 'Invitation sent!',
            });
          }
        })
        .catch((err) => {
          Vue.notify({
            group: 'general',
            text: err.body.message,
            type: 'error',
          });
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
        });
    }
  },

  DELETE_INVITATION(context, data) {
    api.http.delete(`teams/${data.team}/invitations/${data.id}`).then((res) => {
      if (res) {
        context.commit('removeInvitation', data.id);
        Vue.notify({
          group: 'general',
          title: 'Invitation removed!',
        });
      }
    });
  },

  RESEND_INVITATION(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Resending invitation...' },
        { root: true },
      );
      api.http
        .put(`teams/${data.team}/invitations/${data.id}`)
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          if (res.ok) {
            Vue.notify({
              group: 'general',
              title: 'Invitation resend!',
            });
          }
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            text: err.body.message,
            type: 'error',
          });
        });
    }
  },
};

// mutations
const mutations = {
  setPendingInvitations(state, payload) {
    state.pending = payload.sort((a, b) =>
      a.created_at > b.created_at ? -1 : b.created_at > a.created_at ? 1 : 0,
    );
  },
  addInvitation(state, payload) {
    state.pending = [payload, ...state.pending];
  },
  updateStatus(state, payload) {
    state.status = payload;
  },
  removeInvitation(state, payload) {
    const i = state.pending.map((item) => item.id).indexOf(payload);
    state.pending.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
