<template lang="html">
  <div class="dropdown__item__wrapper">
    <div class="dropdown__item" v-if="currentDepth == depth" @click="handleSubItemClick(item)">
      <svg-icon v-if="resolveProp('icon', item)" :icon="resolveProp('icon', item)"></svg-icon>
      <profile-pic v-if="resolveProp(imgprop, item) && showImage" :src="resolveProp(imgprop, item)" :size='25' :number="counter" small-number></profile-pic>
      <span>{{ resolveProp(prop, item) }}</span>
    </div>

    <dropdown-submenu
    v-if="resolveProp('submenu', item)"
    :prop="item.submenu.prop"
    :items="item.submenu.items"
    :keyProp="item.submenu.keyProp"
    :message="item.submenu.message"
    :currentDepth="currentDepth"
    :depth="depth + 1"
    :backToPreviousMenu="backToPreviousMenu"
    :handleItemClick="handleItemClick" />
  </div>
</template>

<script>
import Helper from '@/helpers/SiteHelper'
export default {
  name: 'DropdownItem',
  props: {
    item: {
      type: [Object],
    },
    align: {
      type: String,
      default: 'center',
    },
    prop: {
      type: String,
      default: 'label',
    },
    keyProp: {
      type: String,
      default: 'id',
    },
    imageProp: {
      type: String,
      default: 'id',
    },
    counterProp: {
      type: String,
      default: 'games',
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: false,
    },
    currentDepth: {
      type: Number,
      default: 0,
    },
    depth: {
      type: Number,
      default: 0,
    },
    backToPreviousMenu: {
      type: Function,
      required: true,
    },
    handleItemClick: {
      type: Function,
      required: true,
    }
  },
  data: function() {
    return {

    }
  },
  methods: {
    resolveProp: Helper.resolve,
    handleBackClick() {
      this.backToPreviousMenu()
    },
    handleSubItemClick(item) {
      this.handleItemClick(item)
    }
  },
  computed: {
    imgprop() {
      return this.imageProp ? this.imageProp : 'photo_url'
    },
    counter() {
      if (this.showCounter && this.counterProp) {
        let games = Helper.resolve(this.counterProp, this.item)
        return games.filter(x => x.event_id == this.item.event_id ).length
      } else {
       return false
      }
    }
  }
}
</script>
