import store from '@/store'

export default (request, next) => {
  next((response) => {
    if (response.status === 401) {
      store.commit('AUTH_LOGOUT')
      window.location.reload(true)
    }
  })
}
