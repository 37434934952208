<template lang="html">
  <div class="container-full">
    <div class="DashboardTop">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardTop'
}
</script>
