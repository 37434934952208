import api from "@/api/index";

const state = {
  notifications: null,
  announcements: null,
}
// getters
const getters = {
  hasUnread: (state) => {
    return state.notifications ? state.notifications.filter(item => { return item.read == false }).length > 0 : null
  }
}

// actions
const actions = {
  GET_NOTIFICATIONS(context) {
    api.http.get('notifications')
    .then(res => {
      if (res.ok) {
        context.commit('setNotifications', res.body.notifications)
        context.commit('setAnnouncements', res.body.announcements)
      }
    })
  },
  MARK_AS_READ(context, data) {
    api.http.put(`notifications`, {notifications: data})
    .then(() => {
      context.commit('markAsRead', data)
    })
  },

  REMOVE_NOTIFICATIONS(context, data) {
    api.http.post(`notifications/delete`, {notifications: data})
    .then(() => {
      context.commit('removeNotifications', data)
    })
  },
}

// mutations
const mutations = {
  setNotifications(state, payload) {
    state.notifications = payload
  },
  setAnnouncements(state, payload) {
    state.announcements = payload
  },
  addNotification(state, payload) {
    state.notifications.unshift(payload)
  },
  removeNotifications(state, payload) {
    payload.forEach(notification => {
      const i = state.notifications.map(item => item.id).indexOf(notification)
      state.notifications.splice(i, 1)
    })
  },
  markAsRead(state, payload) {
    payload.forEach(notification => {
      const i = state.notifications.map(item => item.id).indexOf(notification)
      state.notifications[i].read = true
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
