const SettingsRoutes = [
  {
    path: '/settings',
    name: 'profile-settings',
    component: () => import('@/views/Settings/ProfileSettings'),
    meta: {
      auth: true,
      title: 'Settings',
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/Settings/Subscription'),
    meta: {
      auth: true,
      title: 'Subscription',
      requiredRole: 'admin',
    },
  },
  {
    path: '/invite-selectors',
    name: 'invite-selectors',
    component: () => import('@/views/Settings/InviteSelectors'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      title: 'Invite Selectors',
      requiredRole: 'admin',
    },
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: () => import('@/views/Settings/Invitations'),
    meta: {
      auth: true,
      title: 'Invite Selectors',
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/Settings/Help'),
    meta: {
      auth: true,
      title: 'Help',
    },
  },
];

export default SettingsRoutes;
