import Vue from 'vue'
import Vuex from 'vuex'

// import actions from './actions'
// import mutations from './mutations'
// import getters from './getters'
// import defaults from './defaults'

import auth from './modules/auth'
import user from './modules/user'
import events from './modules/events'
import games from './modules/games'
import status from './modules/statusIndicator'
import invitations from './modules/invitations'
import subscriptions from './modules/subscriptions'
import players from './modules/players'
import payments from './modules/payments'
import submissions from './modules/submissions'
import notifications from './modules/notifications'
import team from './modules/team'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    events,
    user,
    players,
    games,
    status,
    invitations,
    subscriptions,
    submissions,
    payments,
    notifications,
    team,
  },
})
