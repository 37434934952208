const eventRoutes = [
  {
    path: '/events',
    name: 'events',
    component: () => import('@/views/Events/Events'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/events/new',
    name: 'create-event',
    component: () => import('@/views/Events/CreateEvent'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      requiredRole: 'admin',
      breadcrumbs: [
        {
          routeName: 'events',
          name: 'Events',
        },
        {
          routeName: 'create-event',
          name: 'Create event',
        },
      ],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Events/SingleEvent'),
    meta: {
      auth: true,
      subscriptionRequired: true,
    },
  },
  {
    path: '/events/:id/edit',
    name: 'update-event',
    component: () => import('@/views/Events/UpdateEvent'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      requiredRole: 'admin',
      breadcrumbs: [
        {
          routeName: 'events',
          name: 'Events',
        },
        {
          routeName: 'update-event',
          name: 'Edit event',
        },
      ],
    },
  },
]

export default eventRoutes
