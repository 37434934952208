<template>
  <ValidationProvider :name="to.label" :rules="field.rules" :class="`field field--${to.layout}`" tag="div" v-slot="{ errors }">
    <label :for="field.key" v-if='to.label' :class="['field__label', (showOptions || (model[field.key] && model[field.key].length > 0)) ? 'field__label--active' : '']">{{ to.label }}</label>
    <div class="draggable">
      <draggable v-model="model[field.key]" class="draggable__selected" ghost-class="draggable-option--ghost">
        <div class="draggable-option" v-for="item in model[field.key]" :key="item.id">
          {{ item.short_name}}
          <div class="draggable-option__remove" @click="removeItem(item)">
            <svg-icon icon="x"/>
          </div>
        </div>
      </draggable>
      <div class="draggable-add" v-click-outside="hideOptions">
        <div class="draggable-add__button" @click="toggleOptions">
          <svg-icon icon="add"></svg-icon>
        </div>

        <div class="draggable-add__options" v-if="showOptions">
          <div class="draggable-add__option" v-for="position in positions" :key="position.id" @click="addItem(position)">
            {{ position.short_name }}
          </div>
        </div>
      </div>
    </div>

    <div :class="['field__underline', showOptions ? 'field__underline--active' : '']"></div>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>
<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import {mapState} from 'vuex';
import baseField from 'vue-formly-bootstrap/src/fields/baseField';
export default {
  mixins: [baseField],
  name: 'PrefferedPositions',
  data() {
    return {
      focused: false,
      showOptions: false,
    }
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused
    },
    toggleOptions() {
      this.showOptions = !this.showOptions
    },
    hideOptions() {
      this.showOptions = false
    },
    removeItem(item) {
      this.model[this.field.key].splice(this.model[this.field.key].indexOf(item), 1);
    },
    addItem(item) {
      let model = this.model[this.field.key]
      if (Array.isArray(model) && model.length < 3 ) {
        if (Array.isArray(model)) {
          this.model[this.field.key] = model.indexOf(item) === -1 ? [...model, item] : [...model]
          this.showOptions = false
        } else {
          this.model[this.field.key] = [item]
        }
      }
    },
  },

  beforeMount() {
    if (!this.$store.state.positions) {
      this.$store.dispatch('games/GET_POSITIONS', this)
    }
  },
  computed: {
    ...mapState({
      positions: state => state.games.positions
    }),
  }
}
</script>
