import api from '@/api';
import Vue from 'vue';

const state = {
  status: '',
  profile: null,
  role: null,
  team: null,
  teams: [],
  divisions: null,
  extra_billing_information: null,
  invitations: {
    status: false,
    pending: [],
  },
};

const getters = {
  getProfile: (state) => state.profile,
  userRole: (state) => state.role,
  currentTeam: (state) => state.profile.current_team_id,
  getTeams: (state) => state.teams,
  hasToFillPayment: (state) =>
    !(state.profile && state.profile.account_type == 'user'
      ? state.profile.has_payment
      : true),
  teamName: (state) => (state.team ? state.team.name : null),
  getUserId: (state) => (state.profile ? state.profile.id : null),
  hasSubscription: (state) =>
    state.team
      ? state.team.subscribed
        ? true
        : state.team.onGenericTrial
        ? true
        : false
      : false,
};

const actions = {
  FETCH_USER({ commit, dispatch }) {
    if (this.profile == null) {
      commit('userRequest');
    }
    api.http
      .get('profile')
      .then((res) => {
        commit('setUserData', res.data);
        dispatch('notifications/GET_NOTIFICATIONS', { root: true });
      })
      .catch(() => {
        commit('userError');
        dispatch('AUTH_LOGOUT');
      });
  },

  REMOVE_USER({ dispatch }) {
    dispatch(
      'status/TOGGLE_LOADER',
      {
        visible: true,
        status: 'Removing account...',
      },
      { root: true },
    );
    return new Promise((resolve, reject) => {
      api.http
        .post('profile/remove')
        .then((res) => {
          dispatch('AUTH_LOGOUT_REMOVED');
          dispatch('status/TOGGLE_LOADER', { visible: false }, { root: true });
          resolve(res);
        })
        .catch((err) => {
          Vue.notify({
            group: 'general',
            title: 'Account not removed',
            text: err.message,
            type: 'error',
          });
          dispatch('status/TOGGLE_LOADER', { visible: false }, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_PROFILE(context, data) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating profile...' },
        { root: true },
      );
      var formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }

      formData.append('_method', 'put');
      api.http
        .post('profile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          context.commit('updateProfile', res.body);
          Vue.notify({
            group: 'general',
            title: 'Profile updated succesfully!',
          });
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Update failed!',
            text: err.body.message,
            type: 'error',
          });
        });
    }
  },

  UPDATE_TEAM(context, payload) {
    if (!context.state.status.isVisible) {
      var data = {
        name: payload.team_name,
        photo: payload.team_photo,
      };
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating team profile...' },
        { root: true },
      );
      var formData = new FormData();
      for (var key in data) {
        formData.append(key, data[key]);
      }

      formData.append('_method', 'put');
      api.http
        .post('team', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          context.commit('updateTeam', res.body);
          Vue.notify({
            group: 'general',
            title: 'Team updated succesfully!',
          });
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Update failed!',
            text: err.body.message,
            style: 'error',
          });
        });
    }
  },

  FETCH_EXTRA_BILLING({ commit }) {
    api.http.get('profile/billing-information').then((res) => {
      commit('setTeamBilling', res.body.extra_billing_information);
    });
  },

  UPDATE_EXTRA_BILLING(context, payload) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating billing...' },
        { root: true },
      );
      api.http
        .put(`profile/billing-information`, {
          information: payload,
        })
        .then(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Billing information updated!',
          });
          context.commit('setTeamBilling', payload);
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Update failed',
            style: 'error',
          });
        });
    }
  },

  GET_DIVISIONS(context) {
    if (context.state.profile?.current_team_id)
      api.http
        .get(`teams/${context.state.profile.current_team_id}/divisions`)
        .then((res) => {
          context.commit('setDivisions', res.body);
        });
  },

  SAVE_DIVISION(context, payload) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Creating division...' },
        { root: true },
      );
      return new Promise((resolve, reject) => {
        api.http
          .post(
            `teams/${context.state.profile.current_team_id}/divisions`,
            payload,
          )
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: 'Division created!',
            });
            context.dispatch('GET_DIVISIONS');
            resolve(res);
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: 'Division not saved',
              style: 'error',
            });
            reject(err);
          });
      });
    }
  },

  UPDATE_DIVISION(context, payload) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating division...' },
        { root: true },
      );
      api.http
        .put(
          `teams/${context.state.profile.current_team_id}/divisions/${payload.id}`,
          payload,
        )
        .then(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Division updated!',
          });
          context.dispatch('GET_DIVISIONS');
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Division not updated',
            style: 'error',
          });
        });
    }
  },
  REMOVE_DIVISION(context, payload) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Deleting division...' },
        { root: true },
      );
      api.http
        .delete(
          `teams/${context.state.profile.current_team_id}/divisions/${payload}`,
        )
        .then(() => {
          context.commit('removeDivision', payload);
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Division removed!',
          });
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: 'Division removing error',
            content: err.message,
            style: 'error',
          });
        });
    }
  },

  GET_INVITATIONS(context) {
    context.commit('updateInvitationStatus', false);
    api.http.get(`profile/invitations`).then((res) => {
      context.commit('setInvitations', res.body);
      context.commit('updateInvitationStatus', true);
    });
  },

  ACCEPT_INVITATION(context, invitation) {
    api.http.put(`profile/invitations/${invitation}`).then((res) => {
      if (res) {
        context.commit('removeInvitation', invitation);
        context.dispatch('FETCH_USER');
        Vue.notify({
          group: 'general',
          title: 'Invitation accepted!',
        });
      }
    });
  },

  DECLINE_INVITATION(context, invitation) {
    api.http.delete(`profile/invitations/${invitation}`).then((res) => {
      if (res) {
        context.commit('removeInvitation', invitation);
        Vue.notify({
          group: 'general',
          title: 'Invitation removed!',
        });
      }
    });
  },

  SWITCH_TEAM(context, data) {
    api.http.post(`profile/switch-team`, { team: data }).then(() => {
      context.dispatch('FETCH_USER');
    });
  },
};

const mutations = {
  userRequest: (state) => {
    state.status = 'loading';
  },
  setUserData: (state, profile) => {
    let team = profile.teams.filter(
      (item) => item.id == profile.current_team_id,
    )[0];
    state.status = 'success';
    state.profile = profile;
    state.teams = profile.teams;
    state.role =
      profile.account_type == 'player'
        ? 'player'
        : profile.role
        ? profile.role
        : 'selector';
    state.team = team;
    state.divisions = profile.divisions;
  },

  updateProfile: (state, payload) => {
    state.profile = payload;
  },

  updateTeam: (state, payload) => {
    state.team = payload;
  },

  setTeamBilling: (state, payload) => {
    state.extra_billing_information = payload;
  },

  userError: (state) => {
    state.status = 'error';
  },

  setUserRatings: (state, payload) => {
    let index = state.profile.ratings.findIndex(
      (item) => item.player_lineup_id === payload.player_lineup_id,
    );
    if (index != -1) {
      state.profile.ratings[index] = payload;
    } else {
      state.profile.ratings = [...state.profile.ratings, payload];
    }
    if (state.profile.rated.indexOf(payload.player_lineup_id) == -1)
      state.profile.rated = [...state.profile.rated, payload.player_lineup_id];
  },

  setDivisions: (state, payload) => {
    state.divisions = payload;
  },
  removeDivision: (state, payload) => {
    const i = state.divisions.map((item) => item.id).indexOf(payload.id);
    state.divisions.splice(i, 1);
  },
  setInvitations(state, payload) {
    state.invitations.pending = payload.sort((a, b) =>
      a.created_at > b.created_at ? -1 : b.created_at > a.created_at ? 1 : 0,
    );
  },
  updateInvitationStatus(state, payload) {
    state.invitations.status = payload;
  },
  removeInvitation(state, payload) {
    const i = state.invitations.pending.map((item) => item.id).indexOf(payload);
    state.invitations.pending.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
