<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim ref="divisionsForm" :disabled="disabled">
    <form @submit.prevent="handleSubmit(handleDivisionSubmit)" class="Form">
      <formly-form :form="form" :model="model" :fields="fields" tag="div" class="Form__group"></formly-form>
      <button class="btn btn--outlined">{{ buttonText }}</button>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "DivisionForm",
  props: {
    division: {
      type: [Object, Boolean],
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      model: {
        division_name: "",
        division_description: "",
        division_range: "",
        year_eligible: ""
      },
      form: {},
      fields: [
        {
          key: "division_name",
          type: "input",
          rules: "required",
          templateOptions: {
            type: "text",
            label: "Division name",
            layout: "full"
          }
        },
        {
          key: "division_description",
          type: "textarea",
          templateOptions: {
            type: "text",
            label: "Description/Eligibility(Optional)",
            layout: "full"
          }
        },
        // {
        //   key: "division_range",
        //   type: "datepicker",
        //   templateOptions: {
        //     range: true,
        //     type: "month",
        //     format: "MMM YYYY",
        //     label: "Age Range",
        //     disabledDate: "future",
        //     layout: "full"
        //   }
        // },
        {
          key: "year_eligible",
          type: "yearSelect",
          templateOptions: {
            range: true,
            type: "year",
            format: "MMM YYYY",
            label: "Age eligibility (players born on or after 1 january of)",
            layout: "full"
          }
        }
      ]
    };
  },
  methods: {
    handleDivisionSubmit() {
      let payload = {
        name: this.model.division_name,
        description: this.model.division_description,
        year_eligible: this.model.year_eligible,
        from_birthday: this.model.division_range[0] || null,
        to_birthday: this.model.division_range[1] || null
      };

      if (this.division) {
        this.$store.dispatch("UPDATE_DIVISION", {
          id: this.division.id,
          ...payload
        });
      } else {
        this.$store.dispatch("SAVE_DIVISION", payload).then(() => {
          this.sanatizeForm();
          this.$emit("completed");
        });
      }
    },
    sanatizeForm() {
      this.model.division_name = "";
      this.model.division_description = "";
      this.model.division_range = "";
      this.model.year_eligible = "";
      this.$refs.divisionsForm.reset();
    },

    setInitialData() {
      if (this.division) {
        this.model.division_name = this.division.name;
        this.model.division_description = this.division.description;
        this.model.division_range = [
          this.division.from_birthday,
          this.division.to_birthday
        ];
        this.model.year_eligible = this.division.year_eligible;
      }
    }
  },
  created() {
    if (this.division) {
      this.setInitialData();
    }
  },
  computed: {
    buttonText() {
      return this.division ? "Update Division" : "Save Division";
    }
  },
  watch: {
    division() {
      if (this.division) {
        this.setInitialData();
      } else {
        this.sanatizeForm();
      }
    }
  }
};
</script>
