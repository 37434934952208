<template lang="html">
  <div class="dropdown-submenu" v-if="currentDepth == depth">
    <div class="dropdown__subitem" @click="handleBackClick">
      <svg-icon icon="chevron-left" />
      <span>Back</span>
    </div>
    <div class="dropdown-submenu__item-wrapper"  v-for="item in items" :key="resolveProp(keyProp, item)">
      <div :class="`dropdown__subitem dropdown__subitem-${depth}`" @click="handleSubItemClick(item)">
        <span>{{ resolveProp(prop, item) }}</span>
      </div>

      <dropdown-submenu
      v-if="resolveProp('submenu', item)"
      :prop="item.submenu.prop"
      :items="item.submenu.items"
      :keyProp="item.submenu.keyProp"
      :message="item.submenu.message"
      :depth="depth + 1"
      :currentDepth="currentDepth"
      :backToPreviousMenu="backToPreviousMenu"/>
    </div>
    <div class="dropdown__empty" v-if="items.length == 0">
      {{message}}
    </div>
  </div>
</template>

<script>
import Helper from '@/helpers/SiteHelper'
export default {
  name: 'Submenu',
  props: {
    depth: {
      type: Number,
      required: true,
    },
    currentDepth: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
    },
    prop: {
      type: String,
      default: 'label',
    },
    keyProp: {
      type: String,
      default: 'id',
    },
    message: {
      type: String,
      default: 'Nothing to show!',
    },
    backToPreviousMenu: {
      type: Function,
      required: true,
    },
    handleItemClick: {
      type: Function,
      required: true,
    }
  },
  methods: {
    resolveProp: Helper.resolve,
    handleBackClick() {
      this.backToPreviousMenu()
    },
    handleSubItemClick(item) {
      this.handleItemClick(item)
    }
  }
}
</script>

<style lang="scss">
</style>
