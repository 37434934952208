<template>
  <section class="tabs">
    <div class="tabs__header">
      <div
        v-for="tab in tabs"
        :class="['tab-title', tab.isActive ? 'tab-title--active' : '']"
        :key="tab.title"
        @click="selectTab(tab)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="tabs__body">
      <slot></slot>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Tabs',
    data() {
      return {
        tabs: [],
        activeTab: null,
      }
    },
    created() {
      this.tabs = this.$children
    },
    mounted() {
      if (this.$route.hash) {
        this.selectTab(
          this.tabs.filter((item) => item.href == this.$route.hash)[0],
        )
      } else {
        this.selectTab(this.tabs[0])
      }
    },
    methods: {
      selectTab(selectedTab) {
        this.tabs.forEach((tab) => {
          if (tab.title == selectedTab.title) {
            this.activeTab = tab.href
          }
          tab.isActive = tab.title == selectedTab.title
        })
      },
    },
    watch: {
      '$route.hash': function(hash) {
        this.selectTab(this.tabs.filter((item) => item.href == hash)[0])
      },
    },
  }
</script>
