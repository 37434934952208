<template lang="html">
  <header>
    <nav class="Header Header--unregistered">
      <div class="container">
        <div class="Header__wrapper Header__wrapper--unregistered">
          <div class="Header__logo">
            <Logo />
          </div>
          <div class="Header__left">
            <div v-if="user.id" class="header-flex">
              <router-link to="/profile" class="nav-link">
                <User />
              </router-link>
              <div
                @click="handleLogOut"
                style="margin-left: 12px"
                class="Header__nav__link clickable"
              >
                Log out
              </div>
            </div>
            <div v-else class="header-flex">
              <router-link to="/login" class="nav-link">Log in</router-link>
              <router-link to="/signup" class="nav-link ml-16"
                >Sign up for Club Account</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import { mapState } from 'vuex';
  import Logo from '@/partials/Logo/Logo';
  import User from '../../components/User.vue';
  export default {
    name: 'Header',
    components: {
      Logo,
      User,
    },
    methods: {
      handleLogOut() {
        this.$store.dispatch('AUTH_LOGOUT');
      },
    },
    computed: {
      ...mapState({
        user: (state) => state.user?.profile,
      }),
    },
  };
</script>
