<template lang="html">
  <div :class="actionClass" @click="handleClick">
    <svg-icon :icon="icon"></svg-icon>
  </div>
</template>

<script>
  export default {
    name: 'Action',
    props: {
      icon: {
        type: String,
        required: false,
      },
      grey: {
        type: Boolean,
        default: false,
      },
      transparent: {
        type: Boolean,
        default: false,
      },
      table: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
      },
      active: {
        type: Boolean,
        default: false,
      },
      reversed: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleClick() {
        this.$emit('actionClicked')
      },
    },
    computed: {
      actionClass() {
        let containerClass = 'Action'
        containerClass = this.grey
          ? `${containerClass} Action--grey`
          : containerClass
        containerClass = this.transparent
          ? `${containerClass} Action--transparent`
          : containerClass
        containerClass = this.table
          ? `${containerClass} Action--table`
          : containerClass
        containerClass = this.active
          ? `${containerClass} Action--active`
          : containerClass
        containerClass = this.reversed
          ? `${containerClass} Action--reversed`
          : containerClass
        containerClass = this.size
          ? `${containerClass} Action--${this.size}`
          : containerClass
        return containerClass
      },
    },
  }
</script>
