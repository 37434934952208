<template>
  <div class="DashboardLoader">
    <Logo />
    <div class="loader-container">
      <div class="loader"></div>
      <div class="loader-shadow"></div>
    </div>
  </div>
</template>

<script>
import Logo from "@/partials/Logo/Logo";
export default {
  name: "DashboardLoader",
  components: { Logo }
};
</script>