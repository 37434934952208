import authInterceptor from './auth'
import config from '@/api/config'

if (config.mock) {
  var exports = [authInterceptor]
} else {
  exports = [authInterceptor]
}

export default exports
