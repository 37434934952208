import Helper from '@/helpers/EventHelper'

const EventConfig = {
  game_lengths: Helper.createLengthOptions([5, 15], false),
  game_break: [...Helper.createLengthOptions([3, 15], false)],
  event_duration: [...Helper.createLengthOptions([30, 240], 15)],
  timer_modification: [...Helper.createTimerModification([-60, 0], 15)],
  event_status: [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Open',
      value: 'open',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
  ],
  attandance: [
    {
      label: 'Registered',
      value: 'registered',
    },
    {
      label: 'Attending',
      value: 'attending',
    },
    {
      label: 'Absent',
      value: 'absent',
    },
  ],
}

export default EventConfig
