import config from './config'
import Vue from 'vue'
import VueResource from 'vue-resource'
import Cookies from 'js-cookie'
import interceptors from './interceptors'
import { echo_instance as Echo } from '@/api/echo'

Vue.use(VueResource)

Vue.http.interceptors.push((request, next) => {
  request.headers.set('X-Requested-With', 'XMLHttpRequest')
  request.headers.set('Authorization', 'Bearer ' + Cookies.get('access_token'))
  request.headers.set('Content-Type', 'application/json')
  request.headers.set('X-Socket-ID', Echo.socketId())
  next()
})

Vue.http.options.emulateJSON = true
Vue.http.options.emulateHTTP = true
Vue.http.options.root = config.root
Vue.http.interceptors.push(...interceptors)

export default Vue
