const AuthRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Auth/Login'),
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Auth/SignUp'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Auth/Register'),
  },
  {
    path: '/registration',
    name: 'register-player',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Players/Registration'),
  },
  {
    path: '/reset-password',
    name: 'password-recovery',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Auth/ForgotPassword'),
  },
  {
    path: '/forgot-username',
    name: 'forgot-username',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Auth/ForgotUsername'),
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    meta: {
      layout: 'unregistered',
    },
    component: () => import('@/views/Auth/ResetPassword'),
  },
]

export default AuthRoutes
