<template lang="html">
  <section :class="dropdownClass" v-click-outside="closeDropdown">
    <div class="dropdown__activation" @click="toggleActive">
      <slot>
        <div class="dropdown__activation__icon">
          <svg-icon icon="more"></svg-icon>
        </div>
      </slot>
    </div>
    <div
      :class="`dropdown__content dropdown__content--${align}`"
      v-show="isActive"
    >
      <div class="dropdown__header">
        <input
          type="search"
          class="input Search__input"
          v-model="searchable"
          placeholder="Type to search"
          ref="search"
          v-if="searchOptions"
        />
        <slot name="searchAction"></slot>
      </div>
      <simplebar class="dropdown__inner">
        <DropdownItem
          v-for="item in filteredItems"
          v-show="!(resolveProp('show', item) == false)"
          :key="resolveProp(keyProp, item)"
          :currentDepth="currentDepth"
          :depth="0"
          :item="item"
          :showCounter="showCounter"
          :counterProp="counterProp"
          :imageProp="imageProp"
          :showImage="showImage"
          :prop="prop"
          :backToPreviousMenu="backToPreviousMenu"
          :handleItemClick="handleItemClick"
        />
      </simplebar>

      <div class="dropdown__empty" v-if="items.length == 0">
        <slot name="empty">
          {{ message }}
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
  import Helper from '@/helpers/SiteHelper'
  import DropdownItem from '@/partials/Dropdown/DropdownItem'

  export default {
    name: 'Dropdown',
    components: { DropdownItem },
    props: {
      items: {
        type: [Array, Boolean],
      },
      align: {
        type: String,
        default: 'center',
      },
      prop: {
        type: String,
        default: 'label',
      },
      keyProp: {
        type: String,
        default: 'id',
      },
      imageProp: {
        type: String,
        default: 'photo_url',
      },
      counterProp: {
        type: String,
        default: 'games',
      },
      showImage: {
        type: Boolean,
        default: false,
      },
      showCounter: {
        type: Boolean,
        default: false,
      },
      message: {
        type: String,
        default: 'Nothing to show!',
      },
      searchOptions: {
        type: [Object, Boolean],
        default: false,
      },
      dark: {
        type: Boolean,
        default: false,
      },
      ignoreOutsideClicks: {
        type: Boolean,
        default: false,
      },
    },
    data: function() {
      return {
        isActive: false,
        currentDepth: 0,
        depth: 0,
        searchable: null,
        filteredItems: this.items,
      }
    },
    methods: {
      toggleActive() {
        this.currentDepth = 0
        this.isActive = !this.isActive
      },
      backToPreviousMenu() {
        this.currentDepth = this.currentDepth - 1
      },

      closeDropdown() {
        if (!this.ignoreOutsideClicks) {
          this.isActive = false
        }
      },

      handleItemClick(item) {
        this.$emit('dropdown-item-click', item)
        if (typeof this.resolveProp('submenu', item) !== 'object') {
          this.closeDropdown()
        } else {
          this.currentDepth = this.currentDepth + 1
        }
      },
      resolveProp: Helper.resolve,
    },
    computed: {
      dropdownClass() {
        let className = 'dropdown'
        className = this.dark ? `${className} dropdown--dark` : className
        return className
      },
    },
    watch: {
      searchable(term) {
        if (term) {
          this.$search(term, this.items, this.searchOptions).then((results) => {
            this.filteredItems = results
          })
        } else {
          this.filteredItems = this.items
        }
      },
      items(val) {
        this.filteredItems = val
      },
    },
  }
</script>
