import Time from '@/helpers/TimeHelper'

const EventHelper = {
  createLengthOptions(vals, step) {
    let options = []
    for( let x = vals[0]; x <= vals[1]; (typeof(step) == 'number' ? x += step : x++)) {
      if (x !== 0 ) {
        options.push({
          label: Time.convertMinutesToRedeableFormat(x),
          value: x,
        })
      }
    }
    return options
  },
  createTimerModification(vals, step) {
    let options = []
    for( let x = vals[0]; x <= vals[1]; (typeof(step) == 'number' ? x += step : x++)) {
      if (x !== 0 ) {
        options.push({
          label: `${x > 0 ? 'Add ' : 'Remove '}${Time.convertSecondsToRedeableFormat(Math.abs(x))}`,
          value: x,
        })
      }
    }
    return options
  }
}


export default EventHelper;
