<template>
  <ValidationProvider :name="to.label" :rules="field.rules" :class="`field field--${to.layout}`" tag="div" v-slot="{ errors }">
    <label :for="field.key" v-if='to.label' :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']">{{ to.label }}</label>
    <v-select
      v-model="model[field.key]"
      :options="options"
      @search:focus="toggleFocus"
      :multiple="false"
      @search:blur="toggleFocus"
      label='name' />
    <div :class="['field__underline', focused ? 'field__underline--active' : '']"></div>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>
<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from 'vue-formly-bootstrap/src/fields/baseField';
import {mapState} from 'vuex'

export default {
  mixins: [baseField],
  name: 'DropdownPrefferedPositions',
  data() {
    return {
      focused: false,
    }
  },
  beforeMount() {
    if (!this.$store.state.positions) {
      this.$store.dispatch('games/GET_POSITIONS', this)
    }
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused
    },
  },
  computed: {
    ...mapState({
      positions: state => state.games.positions
    }),
    options() {
      return this.positions ? this.positions.filter((item) => {
        let keys = this.to.connected_keys
        let taken_positions = []
        keys.forEach(item => {
          if (this.model[item])
          taken_positions.push(this.model[item].name)
        })
        return taken_positions.indexOf(item.name) == -1
      }) : []
    }
  }
}
</script>
