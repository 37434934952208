import api from '@/api/index'

const state = {
  all: null,
  lineups: null,
  positions: null,
  status: {
    all: '',
  },
}
// getters
const getters = {
  getGame: (state) => (id) => {
    if (Array.isArray(state.all) && state.all.length > 0) {
      let game = state.all.filter((item) => item.id == id)[0]
      return game ? game : false
    } else {
      return null
    }
  },
}

// actions
const actions = {
  GET_GAMES(context) {
    api.http.get('games').then((res) => {
      if (res.ok) {
        context.commit('setGames', res.body)
        context.commit('updateAllStatus', 'success')
      }
    })
  },
  GET_POSITIONS(context) {
    api.http.get('positions').then((res) => {
      if (res.ok) {
        context.commit('setPositions', res.body)
      }
    })
  },
  GET_GAME_LINEUPS(context, game) {
    api.http.get(`games/${game.id}/lineups`).then((res) => {
      if (res.ok) {
        context.commit('setLineups', res.body)
      }
    })
  },
  SWITCH_PLAYERS(context, payload) {
    api.http
      .post(`games/${payload.game}/lineups/switch`, payload)
      .then((res) => {
        if (res.ok) {
          context.commit('setLineups', res.body)
        }
      })
  },
  UPDATE_PLAYER_LINEUP_POSITION(context, payload) {
    api.http
      .put(`games/${payload.game_id}/lineups/${payload.id}`, payload)
      .then((res) => {
        if (res.ok) {
          context.dispatch('GET_GAME_LINEUPS', { id: payload.game_id })
        }
      })
  },
  UPDATE_GAME(context, game) {
    api.http.put(`games/${game.id}`, game).then((res) => {
      if (res.ok) {
        context.commit('updateGame', res.body)
      }
    })
  },
  UPDATE_TEAMS_COLORS(context, game) {
    api.http.post(`courts/${game.court_id}/colors`, game).then((res) => {
      if (res.ok) {
        context.dispatch('UPDATE_GAME', game)
      }
    })
  },
}

// mutations
const mutations = {
  setGames(state, payload) {
    state.all = payload
  },
  updateGame(state, payload) {
    let index = state.all.findIndex((x) => x.id == payload.id)
    state[index] = payload
  },
  setPositions(state, payload) {
    state.positions = payload
  },
  setLineups(state, payload) {
    state.lineups = payload
  },
  updateAllStatus(state, status) {
    state.status.all = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
