<template lang="html">
  <div class="SortItem" @click="handleClick">
    <slot></slot>
    <div :class="['SortItem__icon', this.clicks == 2 ? 'SortItem__icon--inverted' : '']" v-if="activeBase === this.sortBy">
      <svg-icon icon="chevron"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortItem',
  props: {
    activeBase: {
      type: String,
      required: false,
      default: '',
    },
    sortBy: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      clicks: 0,
    }
  },
  methods: {
    handleClick() {
      this.clicks = (this.clicks == 2 ? 0 : this.clicks + 1);
      this.$emit('sortChanged', {
        sortedBy: this.sortBy,
        status: this.clicks,
      })
    },
  },
  watch: {
    active(val) {
      if (!val) {
        this.clicks = 0
      }
    }
  }
}
</script>
