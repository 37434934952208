<template>
  <div :class="ratingClass">
    <div class="Rating__star">
      <svg-icon icon="star"></svg-icon>
    </div>
    <div class="Rating__value">{{ rating | rating }}</div>
  </div>
</template>

<script>
  export default {
    name: 'Rating',
    props: {
      rating: {
        type: Number,
      },
    },

    computed: {
      ratingClass() {
        if (this.rating) {
          let isSmall = this.rating <= 2.6
          let isMedium = this.rating <= 3.9

          return `Rating Rating--${isSmall ? 'low' : isMedium ? 'mid' : 'high'}`
        } else {
          return 'Rating'
        }
      },
    },
  }
</script>
