import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import EventRoutes from './modules/events'
import AuthRoutes from './modules/auth'
import SettingsRoutes from './modules/settings'
import GamesRoutes from './modules/games'
import PlayerRoutes from './modules/players'
import OnboardingRoutes from './modules/onboarding'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/r/:slug',
    name: 'event-registration',
    component: () => import('@/views/Registration/Event'),
    meta: {
      layout: 'unregistered',
    },
  },
  ...AuthRoutes,
  ...EventRoutes,
  ...PlayerRoutes,
  ...GamesRoutes,
  ...SettingsRoutes,
  ...OnboardingRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.auth)
  const authed = store.getters.isAuthenticated
  const role = store.getters.userRole
  const hasSubscription = store.getters.hasSubscription

  const roleRequired = to.meta.requiredRole ? to.meta.requiredRole : false
  const subscriptionRequired = to.meta.subscriptionRequired
    ? to.meta.subscriptionRequired
    : false

  if (authRequired && !authed) {
    next('/')
  } else if (roleRequired && role !== roleRequired) {
    if (from.name) next(false)
    else next('/events')
  } else if (subscriptionRequired && !hasSubscription) {
    next('/subscription')
  } else {
    next()
  }
})

export default router
