<template>
  <div class="container-full" v-if="breadcrumbs">
    <section class="Breadcrumbs">
      <button class="btn btn--back mr-8" @click="goBack" v-if="showBack">
        <svg-icon icon="left-arrow" />
      </button>
      <breadcrumb
        v-for="(item, i) in breadcrumbs"
        :key="item.routeName"
        :item="item"
        :current="i == breadcrumbs.length - 1"
        :prev="i == breadcrumbs.length - 2"
        :title="title"
        :prevTitle="prevTitle"
      />
    </section>
  </div>
</template>

<script>
  import Breadcrumb from './Breadcrumb'

  export default {
    name: 'Breadcrumbs',
    props: {
      title: {
        type: [String, Boolean],
        required: false,
      },
      prevTitle: {
        type: [String, Boolean],
        required: false,
      },
    },
    components: { Breadcrumb },
    data: function() {
      return {
        breadcrumbs: false,
        showBack: false,
      }
    },
    created() {
      this.breadcrumbs = this.$route.meta.breadcrumbs
      this.showBack = this.$route.meta.back
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>
