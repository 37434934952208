import Vue from 'vue';
import api from '@/api/index';
import router from '@/router/index';

const state = {
  status: {
    all: '',
    single: '',
  },
  all: null,
  current: {
    id: null,
  },
  games: [],
};
// getters
const getters = {
  getEvent: (state) => (id) => {
    if (Array.isArray(state.all) && state.all.length > 0) {
      return state.all.filter((item) => item.id == id)[0];
    } else {
      state.dispatch('GET_EVENTS');
    }
  },
  singleEventStatus: (state) => state.status.single,
  eventListStatus: (state) => state.status.all,
};

// actions
const actions = {
  GET_EVENTS({ commit }) {
    api.http.get('events').then((res) => {
      commit('setEvents', { events: res.body });
      commit('updateAllStatus', 'success');
    });
  },

  GET_EVENT({ commit }, id) {
    commit('updateSingleStatus', 'loading');
    api.http.get(`events/${id}`).then((res) => {
      commit('setCurrentEvent', { event: res.body });
      commit('updateSingleStatus', 'success');
    });
  },

  GET_GAMES_FOR_EVENT({ commit }, id) {
    api.http.get(`events/${id}/games`).then((res) => {
      commit('setEventGames', { games: res.body });
    });
  },

  POST_EVENT(context, data) {
    context.dispatch(
      'status/TOGGLE_LOADER',
      { visible: true, status: 'Creating event..' },
      { root: true },
    );
    api.http
      .post('events', data)
      .then((res) => {
        context.dispatch(
          'status/TOGGLE_LOADER',
          { visible: false },
          { root: true },
        );
        if (res.status == 201) {
          context.dispatch('GET_EVENTS');
          Vue.notify({
            group: 'general',
            title: 'Event created succesfully!',
          });
          router.push('/events');
        }
      })
      .catch((err) => {
        context.dispatch(
          'status/TOGGLE_LOADER',
          { visible: false },
          { root: true },
        );
        Vue.notify({
          group: 'general',
          title: err,
        });
      });
  },

  PUT_EVENT(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Updating event..' },
        { root: true },
      );
      api.http
        .put(`events/${data.id}`, data)
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          if (res.ok) {
            context.dispatch('GET_EVENTS');
            context.dispatch('GET_EVENT', data.id);
            Vue.notify({
              group: 'general',
              title: 'Event updated succesfully!',
            });
            router.push(`/events/${data.id}`);
          }
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: err.statusText,
            text: err.body.message,
            type: 'error',
          });
        });
    }
  },

  DELETE_EVENT(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Deleting event..' },
        { root: true },
      );
      api.http
        .delete(`events/${data.id}`, data)
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          if (res.ok) {
            context.dispatch('GET_EVENTS');
            Vue.notify({
              group: 'general',
              title: 'Event removed!',
            });
            router.push(`/events`);
          }
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: err.statusText,
            text: err.body.message,
            type: 'error',
          });
        });
    }
  },

  DELETE_EVENTS(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Deleting events..' },
        { root: true },
      );
      api.http
        .delete(`events?ids=${data}`)
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          if (res.ok) {
            context.dispatch('GET_EVENTS');
            Vue.notify({
              group: 'general',
              title: 'Events removed!',
            });
          }
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: err.statusText,
            text: err.body.message,
            type: 'error',
          });
        });
    }
  },

  DUPLICATE_EVENTS(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Duplicating events..' },
        { root: true },
      );
      api.http
        .post(`events/duplicate?ids=${data}`)
        .then((res) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          if (res.ok) {
            context.dispatch('GET_EVENTS');
            Vue.notify({
              group: 'general',
              title: 'Events duplicated',
            });
          }
        })
        .catch((err) => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          );
          Vue.notify({
            group: 'general',
            title: err.statusText,
            text: err.body.message,
            type: 'error',
          });
        });
    }
  },

  ADD_PLAYER_TO_EVENT(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Adding player to event..' },
        { root: true },
      );
      return new Promise((resolve, reject) => {
        api.http
          .post(`players/${data.player}/submissions`, data)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: `${data.name} was added to the event`,
              duration: 6000,
            });
            context.dispatch('GET_EVENT', data.event);
            context.dispatch('players/GET_PLAYERS', '', { root: true });
            resolve(res);
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: "Couldn't add player to the event",
              text: err.body.message,
              type: 'error',
            });
            reject(err);
          });
      });
    }
  },

  GENERATE_LINEUPS(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Generating linups..' },
        { root: true },
      );
      return new Promise((resolve, reject) => {
        api.http
          .post(`events/${data.event}/lineups`, data)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: 'Linups generation completed!',
            });
            context.dispatch('GET_EVENT', data.event);
            context.dispatch('players/GET_PLAYERS', '', { root: true });
            resolve(res);
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: "Couldn't generate lineups",
              text: err.body.message,
              type: 'error',
            });
            reject(err);
          });
      });
    }
  },

  RESET_LINEUPS(context, data) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Reseting linups..' },
        { root: true },
      );
      return new Promise((resolve, reject) => {
        api.http
          .delete(`events/${data.event}/lineups`)
          .then((res) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: 'Linups reseted!',
            });
            context.dispatch('GET_EVENT', data.event);
            resolve(res);
          })
          .catch((err) => {
            context.dispatch(
              'status/TOGGLE_LOADER',
              { visible: false },
              { root: true },
            );
            Vue.notify({
              group: 'general',
              title: "Couldn't reset lineups",
              text: err.body.message,
              type: 'error',
            });
            reject(err);
          });
      });
    }
  },
};

// mutations
const mutations = {
  setEvents(state, payload) {
    state.all = payload.events.sort((a, b) =>
      a.created_at > b.created_at ? -1 : b.created_at > a.created_at ? 1 : 0,
    );
  },

  updateSingleStatus(state, status) {
    state.status.single = status;
  },

  updateAllStatus(state, status) {
    state.status.all = status;
  },

  setCurrentEvent(state, payload) {
    state.current = payload.event;
  },
  setEventGames(state, payload) {
    state.games = payload.games;
  },
  removeEvent(state, payload) {
    const i = state.all.map((item) => item.id).indexOf(payload.id);
    state.all.splice(i, 1);
  },

  updateEvent(state, payload) {
    const i = state.all.map((item) => item.id).indexOf(payload.id);
    state.all[i] = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
