<template lang="html">
  <div class="SectionTitle">
    <h2 :class="`SectionTitle__title ${titleClass}`">{{ title }}</h2>
    <div class="SectionTitle__content">
      <div class="SectionTitle__divider divider"></div>
      <div class="SectionTitle__actions">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    elclass: {
      type: String,
      required: false,
    }
  },
  computed: {
    titleClass() {
      return this.elclass ? this.elclass : 'title-6 text-semi'
    }
  }
}
</script>
