<template lang="html">
  <div :class="`field field--${to.layout}`">
    <label for="payment-card-element" v-if='to.label' :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']">{{ to.label }}</label>
    <div id="payment-card-element" class="input" @focus="toggleFocus" @blur="toggleFocus"></div>
  </div>
</template>

<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from 'vue-formly-bootstrap/src/fields/baseField';
export default {
  mixins: [baseField],
  name: 'CardField',
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused
    },
  }
}
</script>
