<template lang="html">
  <div :class="tagClass">
    <div class="Tag__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tag',
    props: {
      type: {
        type: String || Array,
        required: false,
        default: 'small',
      },
    },
    computed: {
      tagClass() {
        let c = 'Tag'
        let result = 'Tag'
        if (typeof this.type == Array) {
          this.type.map((item) => {
            result += ` ${c}--${item}`
          })
        } else {
          result += ` ${c}--${this.type}`
        }

        return result
      },
    },
  }
</script>

<style lang="scss">
  @import '../styles/abstracts/include';
  .Tag {
    padding: 9px 13px;
    text-align: center;
    @include font-weight(bold);
    color: color(typography, 4);
    border-radius: 3px;
    display: inline-block;
    min-width: 90px;
    background-color: color(bg, 6);

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      .dropdown {
        margin-left: 10px;
      }
    }
    @mixin dropdownCorrection {
      .icon--more svg g {
        fill: color(bg, 5);
      }
    }

    &--small {
      min-width: 55px;
    }

    &--xs {
      min-width: 35px;
      padding: 5px 6px;
    }

    &--success,
    &--open,
    &--attending {
      color: color(typography, 3);
      background-color: color(primary, 2);
      @include dropdownCorrection;
    }

    &--danger,
    &--closed,
    &--absent {
      color: color(typography, 3);
      background-color: color(primary, 3);
      @include dropdownCorrection;
    }

    &--planned {
      color: color(typography, 3);
      background-color: color(primary, 1);
      @include dropdownCorrection;
    }

    &--payment {
      border: 1px solid color(bg, 1);
      padding: 5px;
      @include font-weight(semibold);
      @include dropdownCorrection;
    }
  }
</style>
