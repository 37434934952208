<template>
  <ValidationProvider :name="field.key" :rules="field.rules" :class="`field field--${to.layout}`" tag="div" v-slot="{ errors }">
      <label :for="field.key" v-if='to.label' :class="['field__label', focused || model[field.key] ? 'field__label--active' : '']">{{ to.label }}</label>
      <input :type="to.type" v-model="model[field.key]" class="input" @focus="toggleFocus" @blur="toggleFocus" :id="field.key" :name="field.key">
      <div :class="['field__underline', focused ? 'field__underline--active' : '']"></div>
      <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>
<script>
//not required but this baseField has a lot of useful stuff already in it, check it out
import baseField from 'vue-formly-bootstrap/src/fields/baseField';
export default {
  mixins: [baseField],
  name: 'InputField',
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    toggleFocus() {
      this.focused = !this.focused
    },
  }
}
</script>
