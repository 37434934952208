<template lang="html">
  <div class="ScreenTitle__wrapper">
    <div class="ScreenTitle">
      <h1 class="ScreenTitle__title title-3 mb-0 mt-0" v-if="title">
        {{ title }}
      </h1>
      <div class="ScreenTitle__actions" v-if="action && actionPermission">
        <router-link :to="action" class="btn btn--primary">{{
          button
        }}</router-link>
      </div>
      <div class="ScreenTitle__actions" v-if="!action && button">
        <div class="btn btn--dark">{{ button }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ScreenTitle',
    props: {
      title: {
        type: String,
        required: false,
      },
      button: {
        type: String,
        required: false,
      },
      action: {
        type: [String, Boolean],
        required: false,
      },
      actionPermission: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>
