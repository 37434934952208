import api from '@/api/index'

const state = {
  available: null,
  current: null,
  subscriptions: null,
}
// getters
const getters = {}

// actions
const actions = {
  GET_PLANS(context) {
    api.http.get('plans').then((res) => {
      if (res.ok) {
        context.commit('setAvailablePlans', res.body.available)
        context.commit('setCurrentPlan', res.body.current)
        context.commit('setSubscriptions', res.body.subscriptions)
      }
    })
  },
  SUBSCRIBE(context, data) {
    api.http
      .post(`teams/${data.team}/subscription`, { plan: data.plan.id })
      .then(() => {
        context.dispatch('GET_PLANS')
        context.dispatch('FETCH_USER', null, { root: true })
      })
  },

  UPDATE_SUBSCRIPTION(context, data) {
    api.http
      .put(`teams/${data.team}/subscription`, { plan: data.plan.id })
      .then(() => {
        context.dispatch('GET_PLANS')
        context.dispatch('FETCH_USER', null, { root: true })
      })
  },

  CANCEL_SUBSCRIPTION(context, data) {
    api.http
      .delete(`teams/${data.team}/subscription`, { plan: data.plan.id })
      .then(() => {
        context.dispatch('GET_PLANS')
        context.dispatch('FETCH_USER', null, { root: true })
      })
  },
}

// mutations
const mutations = {
  setAvailablePlans(state, payload) {
    state.available = payload
  },
  setCurrentPlan(state, payload) {
    state.current = payload
  },
  setSubscriptions(state, payload) {
    state.subscriptions = payload
    let activeSubscription = payload ? payload[0] : false

    if (activeSubscription) {
      let current = state.available.filter(
        (item) => item.id == activeSubscription.provider_plan,
      )[0]

      current.ends_at = activeSubscription.ends_at
      current.trial_ends_at = activeSubscription.trial_ends_at
      state.current = current
    } else {
      state.current = null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
