import Vue from 'vue'
import api from '@/api/index'

const state = {
  members: [],
  team: null,
  status: {
    members: '',
  },
}

const actions = {
  GET_TEAM(context, team_id) {
    api.http.get(`teams/${team_id}`).then((res) => {
      if (res.ok) {
        context.commit('setTeam', res.body)
        context.commit('updateAllStatus', 'success')
      }
    })
  },
  REMOVE_MEMBER(context, data) {
    if (!context.state.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Removing team member...' },
        { root: true },
      )
      api.http
        .delete(`teams/${data.team_id}/team_members/${data.member.id}`)
        .then(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          context.commit('removeMember', data.member.id)
          Vue.notify({
            group: 'general',
            title: 'Team member removed!',
          })
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Profile update failed!',
            style: 'error',
          })
        })
    }
  },
}

const mutations = {
  updateMembersStatus(state, status) {
    state.status.members = status
  },
  setTeam(state, data) {
    state.team = data
    state.members = data.users
  },
  removeMember(state, data) {
    state.members = state.members.filter((item) => item.id !== data)
  },
}

export default {
  namespaced: true,
  state,

  actions,
  mutations,
}
