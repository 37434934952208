const PlayerRoutes = [
  {
    path: '/players',
    name: 'players',
    component: () => import('@/views/Players/Players'),
    meta: {
      auth: true,
      subscriptionRequired: true,
    },
  },
  {
    path: '/players/new',
    name: 'player-create',
    component: () => import('@/views/Players/CreatePlayer'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      back: true,
      breadcrumbs: [
        {
          routeName: 'players',
          name: 'Players',
        },
        {
          routeName: 'player-create',
          name: 'New Player',
        },
      ],
    },
  },
  {
    path: '/players/:id',
    name: 'player',
    component: () => import('@/views/Players/SinglePlayer'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      back: true,
      breadcrumbs: [
        {
          routeName: 'players',
          name: 'Players',
        },
        {
          routeName: 'player',
          param: ':id',
          name: 'Player',
        },
      ],
    },
  },
  {
    path: '/players/:id/edit',
    name: 'player-edit',
    component: () => import('@/views/Players/UpdatePlayer'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      requiredRole: 'admin',
      back: true,
      breadcrumbs: [
        {
          routeName: 'players',
          name: 'Players',
        },
        {
          routeName: 'player',
          param: ':id',
          name: 'Player',
        },
        {
          routeName: 'player-edit',
          param: ':id',
          name: 'Edit',
        },
      ],
    },
  },
  {
    path: '/players/:id/note',
    name: 'create-note',
    component: () => import('@/views/Players/CreateNote'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      breadcrumbs: [
        {
          routeName: 'players',
          name: 'Players',
        },
        {
          routeName: 'player',
          param: ':id',
          name: 'Player',
        },
        {
          routeName: 'create-note',
          param: ':id',
          name: 'New note',
        },
      ],
    },
  },
  {
    path: '/players/:id/note/:note',
    name: 'edit-note',
    component: () => import('@/views/Players/EditNote'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      breadcrumbs: [
        {
          routeName: 'players',
          name: 'Players',
        },
        {
          routeName: 'player',
          param: ':id',
          name: 'Player',
        },
        {
          routeName: 'edit-note',
          param: ':note',
          name: 'Edit Note',
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'player-profile',
    component: () => import('@/views/Players/SinglePlayer'),
    meta: {
      auth: true,
    },
  },
]

export default PlayerRoutes
