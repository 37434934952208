<template lang="html">
  <section id="dashboard">
    <portal-target name="modal"></portal-target>
    <Header
      @menuButtonClicked="toggleMobileMenu"
      :isMenuActive="isMobileMenuActive"
    />
    <section class="App">
      <notifications group="general" />
      <NotificationsGroup />

      <StatusIndicator />
      <Navigation
        :isMenuActive="isMobileMenuActive"
        @routeChanged="toggleMobileMenu"
      />
      <main class="App__main">
        <slot />
      </main>
    </section>
    <fade-transition>
      <DashboardLoader v-if="isLoaded != 'success'" />
    </fade-transition>
  </section>
</template>

<script>
  import { mapState } from 'vuex'

  import Header from '@/partials/Header/Default'
  import Navigation from '@/components/Navigation'
  import StatusIndicator from '@/components/StatusIndicator'
  import NotificationsGroup from '@/components/NotificationsGroup'
  import DashboardLoader from '@/components/DashboardLoader'

  export default {
    name: 'AppLayout',
    components: {
      Header,
      Navigation,
      StatusIndicator,
      NotificationsGroup,
      DashboardLoader,
    },
    data: function() {
      return {
        isMobileMenuActive: false,
      }
    },
    methods: {
      toggleMobileMenu(payload) {
        if (payload != null) {
          this.isMobileMenuActive = payload
        } else {
          this.isMobileMenuActive = !this.isMobileMenuActive
        }
      },
    },
    watch: {
      $route() {
        this.toggleMobileMenu(false)
      },
    },
    computed: {
      ...mapState({
        isLoaded: (state) => state.user.status,
      }),
    },
  }
</script>
