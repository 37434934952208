import store from '@/store';

var permissions = {
  methods: {
    userIs(role) {
      return store.getters.userRole === role;
    },
    userNot(role) {
      return store.getters.userRole !== role;
    },
    hasTeam() {
      return store.getters.currentTeam ? true : false;
    },
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated;
    },
  },
};

export default permissions;
