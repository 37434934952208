<template lang="html">
  <div :class="PictureClass">
    <img
      :src="image"
      :alt="alt"
      v-if="!email"
      :style="{ width: `${size}px`, height: `${size}px` }"
    />
    <v-gravatar
      :email="email"
      :alt="alt"
      :size="size"
      v-else
      default-img="mp"
    />
    <div v-if="number !== false && showNumber" :class="pictureIconClass">
      {{ number > 0 ? number : '!' }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Picture',
    props: {
      src: {
        type: [String, Number],
        required: false,
        default:
          'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y&d=mp',
      },
      alt: {
        type: String,
        required: false,
      },
      size: {
        type: Number,
        required: false,
      },
      number: {
        type: [Number, Boolean],
        required: false,
        default: false,
      },
      showNumber: {
        type: Boolean,
        required: false,
        default: false,
      },
      email: {
        type: String,
        required: false,
      },
      circle: {
        type: Boolean,
        required: false,
      },
      smallNumber: {
        type: Boolean,
        required: false,
      },
      border: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      PictureClass() {
        let containerClass = 'Picture'
        containerClass = this.circle
          ? `${containerClass} Picture--circle`
          : containerClass
        containerClass = this.border
          ? `${containerClass} Picture--border`
          : containerClass
        return containerClass
      },
      image() {
        return this.src
          ? this.src
          : 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y&d=mp'
      },
      pictureIconClass() {
        let className = 'Picture__icon'
        className =
          this.number == 0 ? `${className} Picture__icon--alert` : className
        className = this.smallNumber
          ? `${className} Picture__icon--small`
          : className
        return className
      },
    },
  }
</script>

<style lang="scss"></style>
