<template lang="html">
  <header>
    <nav class="Header">
      <div class="Header__wrapper">
        <div class="Header__logo">
          <Logo />
        </div>
        <div class="Header__title">
          {{ defaultTitle }}
        </div>
        <div class="Header__nav">
          <router-link
            to="/dashboard"
            class="Header__nav__link"
            v-if="userNot('player')"
            >Dashboard</router-link
          >
          <router-link to="/events" class="Header__nav__link"
            >Events</router-link
          >
          <router-link
            to="/players"
            class="Header__nav__link"
            v-if="userNot('player')"
            >Players</router-link
          >
          <!-- <router-link to="/games" class="Header__nav__link">Games</router-link> -->
          <router-link
            to="/profile"
            class="Header__nav__link"
            v-if="userIs('player')"
            >Player Profile</router-link
          >
        </div>
        <div class="Header__left">
          <div
            class="Header__notifications"
            v-click-outside="closeNotifications"
          >
            <div
              :class="[
                'notifications-button',
                showNotifications ? 'notifications-button--active' : '',
              ]"
              @click="toggleNotifications"
            >
              <div class="notifications-icon" v-if="hasUnread"></div>
              <svg-icon icon="bell"></svg-icon>
            </div>
            <Notifications :active="showNotifications" />
          </div>
          <div class="Header__user">
            <router-link :to="{ name: 'profile-settings' }" class="User__link">
              <User />
            </router-link>
          </div>
          <div class="Header__menu" v-click-outside="closeMenu">
            <div
              :class="[
                'Header__button',
                isMenuActive ? 'Header__button--active' : '',
              ]"
              @click="handleClick"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              :class="[
                'Header__menu__nav',
                isMenuActive ? 'Header__menu__nav--active' : '',
              ]"
            >
              <router-link to="/settings" class="Header__nav__link"
                >Settings</router-link
              >
              <router-link
                to="/subscription"
                class="Header__nav__link"
                v-if="userIs('admin')"
                >Subscription</router-link
              >
              <router-link
                to="/invite-selectors"
                class="Header__nav__link"
                v-if="userIs('admin')"
              >
                Selectors</router-link
              >
              <!-- <router-link to="/help" class="Header__nav__link">Help</router-link> -->
              <div @click="handleLogOut" class="Header__nav__link clickable">
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="Header__ghost"></div>
  </header>
</template>

<script>
  import Logo from '@/partials/Logo/Logo'
  import User from '@/components/User'
  import Notifications from '@/components/Notifications'

  export default {
    name: 'Header',
    components: { Logo, User, Notifications },
    props: {
      isMenuActive: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: function() {
      return {
        title: this.$route.meta.title,
        showNotifications: false,
        showMenu: false,
      }
    },
    methods: {
      handleClick() {
        this.$emit('menuButtonClicked', !this.isMenuActive)
      },

      closeMenu() {},

      closeNotifications() {
        this.showNotifications = false
      },

      toggleNotifications() {
        this.$emit('menuButtonClicked', false)
        this.$nextTick(function() {
          this.showNotifications = !this.showNotifications
        })
      },
      handleLogOut() {
        this.$store.dispatch('AUTH_LOGOUT')
      },
    },
    watch: {
      isMenuActive(val) {
        this.showNotifications = val ? false : this.showNotifications
      },
    },
    computed: {
      hasUnread() {
        return this.$store.getters['notifications/hasUnread']
      },

      teamName() {
        return this.$store.getters.teamName
      },

      defaultTitle() {
        return this.$route.meta.title
          ? this.$route.meta.title
          : this.teamName
          ? this.teamName
          : ''
      },
    },
  }
</script>

<style lang="scss"></style>
