/* eslint-disable no-case-declarations */
import moment from 'moment';

const TimeHelper = {
  format: 'YYYY-MM-DD HH:mm:ss',
  differenceBetweenUtcDateAndNowInSeconds(datetime) {
    let currentTime = this.getFormattedCurrentDateUTC();
    let comparisonTime = this.formatDate(datetime);

    return moment
      .duration(
        moment(currentTime, this.format).diff(
          moment(comparisonTime, this.format),
        ),
      )
      .asSeconds();
  },

  getFormattedCurrentDateUTC() {
    return moment().utc().format(this.format);
  },

  formatDate(date) {
    return moment(date).format(this.format);
  },

  convertUtcToLocal(date) {
    return moment.utc(date).toISOString();
  },

  convertLocalToUTC(date) {
    let now = new Date(date);
    return new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
    );
  },

  dateYearsFromNow(years) {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + years);
    return currentDate;
  },

  convertMinutesToRedeableFormat(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    let hoursString =
      rhours > 0 ? `${rhours} hour${rhours > 1 ? 's ' : ' '}` : '';
    let minutesString =
      rminutes > 1 ? `${rminutes} minute${rminutes > 1 ? 's' : ''}` : '';
    return `${hoursString}${minutesString}`;
  },

  convertSecondsToRedeableFormat(n) {
    var num = n;
    var minutes = num / 60;
    var rminutes = Math.floor(minutes);
    var seconds = (minutes - rminutes) * 60;
    var rseconds = Math.round(seconds);
    let minutesString =
      rminutes > 0 ? `${rminutes} minute${rminutes > 1 ? 's ' : ' '}` : '';
    let secondsString =
      rseconds > 1 ? `${rseconds} second${rseconds > 1 ? 's' : ''}` : '';
    return `${minutesString}${secondsString}`;
  },

  generateHours(interval, start_time, end_time) {
    let times = [];
    start_time = start_time * 60 || 0;
    end_time = end_time * 60 || 24 * 60;
    let ap = ['AM', 'PM'];

    //loop to increment the time and push results in array
    for (var i = 0; start_time < end_time; i++) {
      var hh = Math.floor(start_time / 60); // getting hours of day in 0-24 format
      var mm = start_time % 60; // getting minutes of the hour in 0-55 format
      const hours = hh == 12 ? 12 : ('0' + (hh % 12)).slice(-2);
      times[i] = {
        label: hours + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)],
        val: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
      };
      start_time = start_time + interval;
    }

    return times;
  },
};

export default TimeHelper;
