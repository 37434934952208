<template lang="html">
  <ValidationProvider
    :name="to.label"
    :rules="field.rules"
    tag="div"
    ref="provider"
    v-slot="{ errors }"
    class="UploadProfilePicture__wrapper"
  >
    <label class="UploadProfilePicture" :for="field.key">
      <img
        :src="model[field.key]"
        class="UploadProfilePicture__preview"
        v-if="!preview"
      />
      <img :src="preview" class="UploadProfilePicture__preview" v-else />
      <input
        type="file"
        ref="image"
        :name="field.key"
        :id="field.key"
        class="UploadProfilePicture__field"
        @change="handleFileSelected"
        accept="image/*"
        crossorigin
      />
      <div class="UploadProfilePicture__info">
        <svg-icon icon="upload-cloud"></svg-icon>
        <span class="text-uppercase text-semi text-primary">{{
          to.label
        }}</span>
      </div>
    </label>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
  import baseField from 'vue-formly-bootstrap/src/fields/baseField'
  export default {
    mixins: [baseField],
    name: 'UploadProfilePicture',
    data: function() {
      return {
        preview: false,
      }
    },
    methods: {
      async handleFileSelected(event) {
        const valid = await this.$refs.provider.validate(event)

        if (valid.valid) {
          const file = event.target.files[0]

          this.model[this.field.key] = file
          this.preview = URL.createObjectURL(file)
        }

        // if (valid) {
        //   const file = event.target.files[0]
        //   const that = this

        //   reader.readAsDataURL(file)
        //   this.model[this.field.key] = file
        // }
      },
    },
  }
</script>
