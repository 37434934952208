<template lang="html">
  <div class="empty-message__wrapper">
    <div class="Card Card--empty empty-message">
      <div class="empty-message__content text-semi text-grey">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyMessage',
}
</script>
