<template lang="html">
  <section id="unregistered">
    <Header></Header>
    <div class="unregistered">
      <StatusIndicator />
      <notifications group="general" />
      <div class="unregistered__image">

      </div>
      <div class="unregistered__content">
        <div class="unregistered__slot text-light">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from '@/partials/Header/Unregistered';
import StatusIndicator from '@/components/StatusIndicator';
export default {
  name: 'AppLayout',
  components: {
    Header,
    StatusIndicator,
  }
}
</script>
