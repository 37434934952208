<template>
  <div class="Breadcrumb">
    <router-link
      v-if="!current"
      :to="linkDestination"
      class="Breadcrumb__link"
      >{{ prev && prevTitle ? prevTitle : item.name }}</router-link
    >
    <div class="Breadcrumb__current" v-else>{{ title || item.name }}</div>
  </div>
</template>

<script>
  export default {
    name: 'Breadcrumb',
    props: {
      item: {
        type: Object,
        required: true,
      },
      current: {
        type: Boolean,
        required: true,
      },
      prev: {
        type: Boolean,
        required: true,
      },
      title: {
        type: [String, Boolean],
        default: false,
      },
      prevTitle: {
        type: [String, Boolean],
        default: false,
      },
    },
    computed: {
      linkDestination() {
        let destination = {
          name: this.item.routeName,
        }

        if (this.item.param) {
          destination.params = {
            id: this.$route.params.id,
          }
        }

        return destination
      },
    },
  }
</script>
