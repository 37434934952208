<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
  const default_layout = 'default'
  import { mapState } from 'vuex'
  import NotificationsMixin from '@/mixins/Notifications'
  export default {
    name: 'App',
    mixins: [NotificationsMixin],
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout'
      },
      ...mapState({
        user: (state) => (state.user.profile ? state.user.profile : false),
        cardUpFront: (state) =>
          state.user.profile ? state.user.profile.paymentRequired : null,
        isUserLoaded: (state) => state.user.status || false,
        has_payment: (state) =>
          state.user.profile ? state.user.profile.has_payment : true,
        team: (state) =>
          state.user.team && state.user.profile.account_type == 'user'
            ? state.user.team
            : false,
      }),
    },
    created() {
      if (this.isAuthenticated) {
        this.$store.dispatch('FETCH_USER')
      }
    },
    beforeUpdate() {
      this.assessPayment(this.has_payment)
    },
    methods: {
      assessPayment(val) {
        if (this.cardUpFront) {
          if (this.isUserLoaded == 'success' && val !== undefined) {
            if (
              !val &&
              this.userIs('admin') &&
              this.$route.name !== 'payment-info'
            ) {
              this.$router.push({ name: 'payment-info' })
            } else if (val && this.$route.name == 'payment-info') {
              this.$router.push({ name: 'subscription' })
            }
          }
        }
      },

      listenOnUserChannel() {
        this.$echo
          .private(`user.${this.user.id}`)
          .notification((notification) => {
            this.handleNotification(notification, true)
          })
      },
      listenOnTeamChannel() {
        this.$echo
          .private(`team.${this.team.id}`)
          .notification((notification) => {
            this.handleNotification(notification)
          })
      },
    },
    watch: {
      has_payment(val) {
        this.assessPayment(val)
      },
      user(val) {
        if (val) {
          this.listenOnUserChannel()
        }
      },
      team(val) {
        if (val) {
          this.listenOnTeamChannel()
        }
      },
    },
  }
</script>

<style lang="scss">
  @import 'styles/main';
</style>
