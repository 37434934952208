import Vue from 'vue'
import Cookies from 'js-cookie'
import ErrorHelper from '@/helpers/ErrorHelper'
import Auth from '@/helpers/AuthHelper'
import api from '@/api'
import store from '@/store'

const state = {
  access_token: Cookies.get('access_token') || '',
  status: '',
  reset_requested: false,
  reset_status: false,
}

const getters = {
  isAuthenticated: (state) => !!state.access_token,
  authStatus: (state) => state.status,
  hasSendRequest: (state) => state.reset_requested,
}

const actions = {
  AUTH_REQUEST({ commit, dispatch }, payload) {
    if (!store.state.status.isVisible) {
      let config
      switch (payload.action) {
        case 'register':
          dispatch('status/TOGGLE_LOADER', {
            visible: true,
            status: 'Registering new user...',
          })
          config = Auth.prepareRegisterData(payload)
          break
        case 'password-reset':
          dispatch('status/TOGGLE_LOADER', {
            visible: true,
            status: 'Resetting password...',
          })
          config = Auth.prepareResetPasswordData(payload)
          break
        default:
          dispatch('status/TOGGLE_LOADER', {
            visible: true,
            status: 'Loging in...',
          })
          config = Auth.prepareLoginData(payload)
          break
      }

      return new Promise((resolve, reject) => {
        commit('authRequest')
        let callOptions = config.options ? config.options : {}
        api.http
          .post(config.url, config.content, callOptions)
          .then((res) => {
            let access_token = res.data.token
            dispatch('status/TOGGLE_LOADER', { visible: false })

            Cookies.set('access_token', access_token, {
              expires: config.content.remember ? 365 : 1,
            })

            api.http.headers.common['Authorization'] = access_token

            commit('authSuccess', access_token)
            dispatch('FETCH_USER')
            resolve(res)
          })
          .catch((err) => {
            dispatch('status/TOGGLE_LOADER', { visible: false })
            let errorMessage = ErrorHelper.createErrorMessage(err)
            Vue.notify({
              group: 'general',
              title: config.title,
              text: errorMessage.string,
              type: 'error',
            })
            Cookies.remove('access_token')
            reject(err)
          })
      })
    }
  },

  AUTH_LOGOUT({ commit }) {
    Cookies.remove('access_token')
    return new Promise((resolve, reject) => {
      api.http
        .post('logout')
        .then(() => {
          commit('authLogout')
          resolve()
        })
        .catch((err) => {
          commit('authError', err.resonse.data)
          reject(err)
        })
    })
  },

  AUTH_LOGOUT_REMOVED({ commit }) {
    Cookies.remove('access_token')
    commit('authLogout')
  },

  FORGOTTEN_USERNAME({ commit, dispatch }, payload) {
    if (!store.state.status.isVisible) {
      dispatch('status/TOGGLE_LOADER', {
        visible: true,
        status: 'Requesting username retrieval...',
      })
      api.http
        .post('username-retrieval', payload)
        .then((res) => {
          commit('requestReset', true)
          dispatch('status/TOGGLE_LOADER', { visible: false })
          Vue.notify({
            group: 'general',
            title: 'Success!',
            text: res.message,
          })
        })
        .catch((err) => {
          dispatch('status/TOGGLE_LOADER', { visible: false })
          Vue.notify({
            group: 'general',
            text: err.body.message,
            type: 'error',
          })
        })
    }
  },

  REQUEST_PASSWORD_RESET({ commit, dispatch }, payload) {
    if (!store.state.status.isVisible) {
      dispatch('status/TOGGLE_LOADER', {
        visible: true,
        status: 'Requesting password reset...',
      })
      api.http
        .post('reset-password', payload)
        .then((res) => {
          commit('requestReset', true)
          dispatch('status/TOGGLE_LOADER', { visible: false })
          Vue.notify({
            group: 'general',
            title: 'Success!',
            text: res.message,
          })
        })
        .catch((err) => {
          dispatch('status/TOGGLE_LOADER', { visible: false })
          Vue.notify({
            group: 'general',
            text: err.body.message,
            type: 'error',
          })
        })
    }
  },

  RESET_PASSWORD({ dispatch }, payload) {
    if (!store.state.status.isVisible) {
      dispatch('status/TOGGLE_LOADER', {
        visible: true,
        status: 'Resetting password...',
      })
      return new Promise((resolve, reject) => {
        api.http
          .post('reset-password/reset', payload)
          .then((res) => {
            dispatch('status/TOGGLE_LOADER', { visible: false })
            Vue.notify({
              group: 'general',
              title: 'Success!',
              text: res.body.message,
            })
            resolve(res)
          })
          .catch((err) => {
            dispatch('status/TOGGLE_LOADER', { visible: false })
            Vue.notify({
              group: 'general',
              text: err.body.message,
              type: 'error',
            })
            reject(err)
          })
      })
    }
  },

  UPDATE_PASSWORD({ dispatch }, payload) {
    if (!store.state.status.isVisible) {
      dispatch('status/TOGGLE_LOADER', {
        visible: true,
        status: 'Updating password...',
      })
      return new Promise((resolve, reject) => {
        api.http
          .put('profile/update-password', payload)
          .then((res) => {
            dispatch('status/TOGGLE_LOADER', { visible: false })
            Vue.notify({
              group: 'general',
              title: 'Success!',
              text: res.body.message,
            })
            resolve(res)
          })
          .catch((err) => {
            dispatch('status/TOGGLE_LOADER', { visible: false })
            Vue.notify({
              group: 'general',
              text: err.body.message,
              type: 'error',
            })
            reject(err)
          })
      })
    }
  },
}

const mutations = {
  authRequest: (state) => {
    state.status = 'loading'
  },
  authSuccess: (state, access_token) => {
    state.status = 'success'
    state.access_token = access_token
    state.hasLoadedOnce = true
  },
  authError: (state, err) => {
    let errors = err.errors ? err.errors : {}
    if (err.error == 'invalid-credentials') {
      errors.invalid_credentials = ['The user credentials were incorrect.']
    }

    state.status = 'error'
    state.hasLoadedOnce = true
    state.errors.record(errors)
  },
  authLogout: (state) => {
    state.access_token = ''
  },
  requestReset: (state, status) => {
    state.reset_requested = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
