import Vue from 'vue';
import Vuex from 'vuex';
import VueMoment from 'vue-moment';
import VueGtm from 'vue-gtm';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Simplebar from 'simplebar-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Notifications from 'vue-notification';
import Draggable from 'vuedraggable';
import Gravatar from 'vue-gravatar';
import VueSlideUpDown from 'vue-slide-up-down';

import VueCurrencyFilter from 'vue-currency-filter';

import App from './App.vue';

// Broadcasting

// Search with fuse
import VueFuse from 'vue-fuse';
Vue.use(VueFuse);

// Portal
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

// Store
import './registerServiceWorker';
import router from './router';
import store from './store';

// Mixins
import permissions from '@/mixins/Permissions';
Vue.mixin(permissions);

/// Layouts
import DefualtLayout from './layouts/Default';
import UnregisteredLayout from './layouts/Unregistered';
import SubmissionLayout from './layouts/Submission';
Vue.component('default-layout', DefualtLayout);
Vue.component('unregistered-layout', UnregisteredLayout);
Vue.component('submission-layout', SubmissionLayout);
///
require('@/partials/Forms/config/forms');
require('@/api/index');
require('@/api/echo');

import Icon from './partials/Icon';
import ScreenTitle from './partials/ScreenTitle';
import SectionTitle from './partials/SectionTitle';
import Action from './partials/Action';
import Picture from './partials/Picture';
import SortItem from '@/partials/SortItem';
import EmptyMessage from '@/partials/EmptyMessage';
import Tag from '@/components/Tag';
import DashboardTop from '@/components/DashboardTop';
import Timer from '@/components/Timer';
import Dropdown from '@/components/Dropdown';
import DropdownSubmenu from '@/partials/Dropdown/Submenu';
import InformationMessage from '@/partials/InformationMessage';
import { FadeTransition } from 'vue2-transitions';
import Tabs from '@/components/Tabs/Tabs';
import Tab from '@/components/Tabs/Tab';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Rating from '@/components/Rating';

Vue.config.productionTip = false;
Vue.use(InlineSvgPlugin);

Vue.component('vue-toggle-slide', VueSlideUpDown);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('draggable', Draggable);
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('info-box', InformationMessage);
Vue.component('dashboard-top', DashboardTop);
Vue.component('svg-icon', Icon);
Vue.component('simplebar', Simplebar);
Vue.component('screen-title', ScreenTitle);
Vue.component('section-title', SectionTitle);
Vue.component('action', Action);
Vue.component('profile-pic', Picture);
Vue.component('sort-item', SortItem);
Vue.component('empty-message', EmptyMessage);
Vue.component('tag-item', Tag);
Vue.component('Timer', Timer);
Vue.component('Dropdown', Dropdown);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('dropdown-submenu', DropdownSubmenu);
Vue.component('fade-transition', FadeTransition);
Vue.component('rating', Rating);

Vue.use(Notifications);
Vue.use(Vuex);
Vue.use(VueMoment);
Vue.component('v-gravatar', Gravatar);

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('rating', function (value) {
  if (!value) return '-';
  return (Math.round(value * 100) / 100).toFixed(2);
});

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ' ',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
});

import { directive as onClickOutside } from 'vue-on-click-outside';
Vue.directive('click-outside', onClickOutside);

Vue.use(VueGtm, {
  id: 'GTM-58V79R4',
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
