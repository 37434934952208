var pjson = require('../../package.json')

const SiteHelper = {
  title(title) {
    if (title) {
      return `${title} | ${pjson.name}`
    } else {
      return pjson.name
    }
  },
  resolve(path, obj = self, separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    let result = properties.reduce((prev, curr) => prev && prev[curr], obj)
    return result
  },
  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

      let comparison = 0
      if (varA == null) {
        comparison = 1
      } else if (varB == null) {
        comparison = -1
      } else if (varA > varB) {
        comparison = 1
      } else if (varA < varB) {
        comparison = -1
      }
      return order === 'desc' ? comparison * -1 : comparison
    }
  },
  parentHasClass(element, classname) {
    if (element.id == 'dashboard') {
      return false
    } else {
      if (element && element.className.split(' ').indexOf(classname) >= 0)
        return true
      return (
        element.parentNode && this.parentHasClass(element.parentNode, classname)
      )
    }
  },
  getParams(url) {
    let params = {}
    let parser = document.createElement('a')
    parser.href = url
    let query = parser.search.substring(1)
    let vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      params[pair[0]] = decodeURIComponent(pair[1])
    }
    return params
  },
  setParams(location, name, value) {
    let url = new URL(location)
    let search_params = url.searchParams
    search_params.set(name, value)
    url.search = search_params.toString()
    return url.toString()
  },
}

export default SiteHelper
