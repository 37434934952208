import './rules.js';

import Vue from 'vue';
import VueFormly from 'vue-formly';
import VueFormlyBootstrap from 'vue-formly-bootstrap';
import vSelect from 'vue-select';
import Datepicker from 'vue2-datepicker';
import TextareaAutosize from 'vue-textarea-autosize';

Vue.use(VueFormly);
Vue.use(TextareaAutosize);
Vue.use(VueFormlyBootstrap);
Vue.component('v-select', vSelect);
Vue.component('v-datepicker', Datepicker);

// custom fields
import Input from '@/partials/Forms/Input';
import Select from '@/partials/Forms/Select';
import DatepickerField from '@/partials/Forms/Datepicker';
import Textarea from '@/partials/Forms/Textarea';
import PrefferedPositions from '@/partials/Forms/PrefferedPositions';
import UploadProfilePicture from '@/partials/Forms/UploadProfilePicture';
import Card from '@/partials/Forms/Card';
import DivisionSelect from '@/partials/Forms/DivisionSelect';
import DropdownPrefferedPositions from '@/partials/Forms/DropdownPrefferedPositions';
import EventSelect from '@/partials/Forms/EventSelect';
import YearSelect from '@/partials/Forms/YearSelect';
import TimeSelector from '@/partials/Forms/TimeSelect';
import RateField from '@/partials/Forms/RateField';

VueFormly.addType('input', Input);
VueFormly.addType('select', Select);
VueFormly.addType('datepicker', DatepickerField);
VueFormly.addType('card', Card);
VueFormly.addType('textarea', Textarea);
VueFormly.addType('prefferedPositions', PrefferedPositions);
VueFormly.addType('dropdownPrefferedPositions', DropdownPrefferedPositions);
VueFormly.addType('profilePicture', UploadProfilePicture);
VueFormly.addType('divisionSelect', DivisionSelect);
VueFormly.addType('eventSelect', EventSelect);
VueFormly.addType('yearSelect', YearSelect);
VueFormly.addType('timeSelect', TimeSelector);
VueFormly.addType('rate-field', RateField);