import Vue from 'vue'
import api from '@/api/index'

const state = {
  all: null,
  default: null,
}
// getters
const getters = {}

// actions
const actions = {
  GET_PAYMENT_METHODS(context) {
    api.http.get('profile/payment-methods').then((res) => {
      if (res.ok) {
        context.commit('setPaymentMethods', res.body)
        context.dispatch('FETCH_USER', null, { root: true })
      }
    })
  },
  SET_DEFAUT_METHOD(context, payment) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Changing default card...' },
        { root: true },
      )
      api.http
        .post(`profile/default-payment`, {
          payment_method: payment,
        })
        .then(() => {
          context.dispatch('GET_PAYMENT_METHODS')
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Default card updated!',
          })
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Something went wrong...',
            type: 'error',
          })
        })
    }
  },
  ADD_PAYMENT_METHOD(context, payment) {
    return new Promise((resolve, reject) => {
      api.http
        .post('profile/payment-methods', {
          payment_method: payment,
        })
        .then(() => {
          context.dispatch('GET_PAYMENT_METHODS')
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Your card was added!',
          })
          resolve()
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Something went wrong...',
            type: 'error',
          })
          reject()
        })
    })
  },

  DELETE_METHOD(context, payment) {
    if (!context.rootState.status.isVisible) {
      context.dispatch(
        'status/TOGGLE_LOADER',
        { visible: true, status: 'Removing card...' },
        { root: true },
      )
      api.http
        .delete(`profile/payment-methods/${payment}`)
        .then(() => {
          context.dispatch('GET_PAYMENT_METHODS')
          context.dispatch('FETCH_USER', null, { root: true })
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Your card was removed!',
          })
        })
        .catch(() => {
          context.dispatch(
            'status/TOGGLE_LOADER',
            { visible: false },
            { root: true },
          )
          Vue.notify({
            group: 'general',
            title: 'Something went wrong...',
            type: 'error',
          })
        })
    }
  },
}

// mutations
const mutations = {
  setPaymentMethods(state, payload) {
    state.all = payload
    state.default = payload.filter((card) => card.default)[0]
  },
  setDefaultPaymentMethod(state, payload) {
    state.default = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
