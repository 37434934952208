const AuthHelper = {
  prepareLoginData(payload) {
    let actionUrl = 'login'
    let remember = payload.remember ? payload.remember : false
    let data = {
      username: payload.username,
      password: payload.password,
      remember: remember,
    }

    return {
      url: actionUrl,
      content: data,
      title: 'Login attempt failed',
      options: false,
    }
  },
  prepareRegisterData(payload) {
    let actionUrl = 'register'
    let isUser = payload.account_type == 'user'
    let data = {
      name: payload.name,
      username: payload.username,
      email: payload.email,
      account_type: isUser ? payload.account_type : 'player',
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      club_name: isUser ? payload.club_name : '',
      position: !isUser ? [payload.position, payload.secondary_position] : null,
      birthday: !isUser ? payload.birthday : null,
      competition_level: !isUser ? payload.competition_level : null,
      special_considerations: !isUser ? payload.special_considerations : null,
      emergency_contact_name: !isUser ? payload.emergency_contact_name : null,
      emergency_contact_number: !isUser
        ? payload.emergency_contact_number
        : null,
    }

    if (payload.invitation_token) {
      data.invitation_token = payload.invitation_token
    }

    if (
      payload.photo &&
      payload.photo !==
      'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y&d=mp'
    ) {
      data.photo = payload.photo
      data.position = JSON.stringify(data.position)
      var formData = new FormData()
      for (var key in data) {
        formData.append(key, data[key])
      }
      let options = {
        headers: {
          'Content-Type': 'muultipart/form-data',
        },
      }

      return {
        url: actionUrl,
        content: formData,
        title: 'Registration failed',
        options: options,
      }
    } else {
      return {
        url: actionUrl,
        content: data,
        title: 'Registration failed',
        options: false,
      }
    }
  },
  prepareResetPasswordData(payload) {
    let actionUrl = 'password/reset'
    let data = {
      token: payload.token,
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    }

    return {
      url: actionUrl,
      content: data,
      title: 'Resetting password failed',
      options: false,
    }
  },
}

export default AuthHelper
