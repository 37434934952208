import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { messages } from 'vee-validate/dist/locale/en.json';

const phoneNumber = {
  message: `Provided phone number is incorrect.`,
  validate(value) {
    return new Promise((resolve) => {
      let regEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\./0-9]*$/g;
      let isPhoneValid = regEx.test(value);
      resolve({ valid: isPhoneValid });
    });
  },
};
extend('phoneNumber', phoneNumber);

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});

extend('birthday_validation', {
  params: ['from_birthday', 'to_birthday', 'year_eligible'],
  validate: (value, { from_birthday, to_birthday, year_eligible }) => {
    if (from_birthday === null && to_birthday == null && year_eligible == null) {
      return true;
    }

    if (year_eligible !== null) {
      return year_eligible <= parseInt(value.substr(0, 4));
    }

    if ((from_birthday == null || new Date(from_birthday) <= new Date(value)) && (to_birthday == null || new Date(to_birthday) >= new Date(value))) {
      return true;
    }
    return false;
  },
  message: "You're outside of the age range for this event.",
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  });
});
