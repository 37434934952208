<template lang="html">
  <div class="Logo">
    <svg-icon icon="netball-logo"></svg-icon>
  </div>
</template>

<script>
  export default {
    name: 'Logo',
  }
</script>
