<template lang="html">
  <div :class="['Notifications', active ? 'Notifications--active' : '', isAnimated ? 'Notifications--animated' : '']" v-if="shouldShow">
      <simplebar class="Notifications__inner">
        <div v-if="notifications && notifications.length > 0">
          <Notification v-for="notification in notifications" :key="notification.id" :read='notification.read' :content="notification"></Notification>
        </div>
        <div class="Notifications__empty" v-else>
          <h3 class="title-5 text-semi text-center text-light">Your inbox is empty!</h3>
          <p class="text-center text-light">Check back later</p>
        </div>
      </simplebar>
      <div class="Notifications__button" v-if="notifications && notifications.length > 0">
        <div to="" class="btn btn--full btn--dark" @click="markAllAsRead">
          Mark all as read
        </div>
      </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Notification from '@/components/Notification'
export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  props: {
    active: {
      type: Boolean,
    }
  },
  data: function() {
    return {
      isAnimated: false,
    }
  },
  mounted() {
    this.assessAnimation()
    window.addEventListener("resize", this.assessAnimation);
  },
  destroyed() {
    window.removeEventListener("resize", this.assessAnimation);
  },
  methods: {
    assessAnimation() {
      if (window.innerWidth < 768) {
        this.$nextTick(() => {
          this.isAnimated = true
        })
      } else {
        this.$nextTick(() => {
          this.isAnimated = false
        })
      }
    },
    markAllAsRead() {
      this.$store.dispatch('notifications/MARK_AS_READ', this.notifications.map(item => item.id))
    },
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications,
      announcements: state => state.notifications.announcements,
    }),
    shouldShow() {
      if (this.isAnimated) {
        return true
      } else {
        return this.active
      }
    }
  }
}
</script>
