<template lang="html">
  <div :class="['Notification', !read ? 'Notification--new' : '']">
    <div class="Notification__image">
      <profile-pic :size="50" :src="sender.photo_url" v-if="sender"></profile-pic>
      <profile-pic :size="50" v-else></profile-pic>
    </div>
    <div class="Notification__content">
      <div class="Notification__header">
        <div class="dot dot--blue"></div>
        <div class="Notification__user text-md text-white text-bold">{{ title }}</div>
        <div class="Notification__extend">
          <dropdown
          :items="items"
          keyProp="id"
          align="right"
          prop="label"
          @dropdown-item-click="handleNotificationAction"/>
        </div>
      </div>
      <div class="Notification__body">
        {{ content.body }}
      </div>
      <div class="Notification__footer">
        <div class="Notification__time text-bold text-sm">{{ createdTime | moment("from", "now") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Time from '@/helpers/TimeHelper'
export default {
  name: 'Notification',
  props: {
    read: {
      type: Boolean,
    },
    content: {
      type: Object,
      required: true,
    }
  },
  data: function () {
    return {
      items: [
        {
          id: 'read',
          label: "Mark as read",
        },
        {
          id: 'remove',
          label: "Remove",
        },
      ]
    }
  },
  methods: {
    handleNotificationAction(payload) {
      switch (payload.id) {
        case 'remove':
          this.$store.dispatch('notifications/REMOVE_NOTIFICATIONS', [this.content.id])
          break;
        case 'read':
          this.$store.dispatch('notifications/MARK_AS_READ', [this.content.id])
          break;
      }
    },
  },
  computed: {
    sender() {
      return typeof(this.content.sender) == 'string' ? JSON.parse(this.content.sender) : this.content.sender
    },
    title() {
      let content = this.content
      return this.sender ? this.sender.name : (content.title ? content.title : 'New notification')
    },
    createdTime() {
      return Time.convertUtcToLocal(this.content.created_at);
    }
  }
}
</script>
