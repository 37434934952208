<template>
  <ValidationProvider
    :name="to.label"
    :rules="field.rules"
    :class="`field field--${to.layout}`"
    tag="div"
    v-slot="{ errors }"
  >
    <label
      :for="field.key"
      v-if="to.label"
      :class="[
        'field__label',
        focused || model[field.key] ? 'field__label--active' : '',
      ]"
    >
      {{ to.label }}
    </label>

    <v-datepicker
      v-model="model[field.key]"
      :range="to.range"
      :type="to.type"
      :popup-style="{ top: '100%', left: 0 }"
      :append-to-body="false"
      :default-panel="to.defaultPanel || 'date'"
      :valueType="valueType"
      :use12h="true"
      :default-value="to.defaultValue || new Date()"
      :format="format"
      :minute-step="10"
      :disabled-date="disabledDates"
      @open="toggleFocus(true)"
      @close="toggleFocus(false)"
    />

    <input v-model="model[field.key]" v-show="false" />
    <div
      :class="['field__underline', focused ? 'field__underline--active' : '']"
    ></div>
    <div class="field__error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
  import Time from '@/helpers/TimeHelper'
  import baseField from 'vue-formly-bootstrap/src/fields/baseField'

  export default {
    mixins: [baseField],
    name: 'Datepicker',
    data() {
      return {
        focused: false,
      }
    },
    methods: {
      toggleFocus(val) {
        this.focused = val != null ? val : !this.focused
      },
      disabledDates(date) {
        let datesMode = this.to.disableDatesMode
        if (datesMode) {
          switch (datesMode) {
            case 'birthday': {
              const time = Time.dateYearsFromNow(-4)
              return date > time
            }
            case 'future':
              return date < new Date()
            case 'past':
              return date > new Date()
            default:
              return false
          }
        } else {
          return false
        }
      },
    },
    computed: {
      valueType() {
        let val = this.to.valueType
        return val ? val : 'YYYY-MM-D'
      },
      format() {
        let format = this.to.format
        return format ? format : 'HH:mm D MMM YY'
      },
    },
  }
</script>
