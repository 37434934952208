const state = {
  isVisible: false,
  content: 'Saving data..',
}
// getters
const getters = {
}

// actions
const actions = {
  TOGGLE_LOADER(context, data) {
    context.commit('setStatus', data.status)
    context.commit('setVisibilityStatus', data.visible);
  }
}

// mutations
const mutations = {
  setVisibilityStatus(state, payload) {
    state.isVisible = payload
  },
  setStatus(state, payload) {
    state.content = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
