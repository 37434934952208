import api from "@/api/index";

const state = {
  status: null,
  submitted: null,
}
// getters
const getters = {
}

// actions
const actions = {
  GET_PLAYER_SUBMISSIONS(context) {
    context.commit('setStatus', 'loading')
    api.http.get('submissions')
    .then(res => {
      if (res.ok) {
        context.commit('setSubmitted', res.body)
        context.commit('setStatus', 'success')
      }
    })
  },

  SUBMIT_APPLICATION(context, payload) {
    api.http.post('submissions', payload)
    .then(res => {
      if (res.ok) {
        context.commit('addToSubmitted', res.body)
        context.dispatch('GET_PLAYER_SUBMISSIONS');
      }
    })
  },

  NEW_PLAYER_SUBMISSION(context, payload) {
    context.dispatch('AUTH_REQUEST', {...payload.data, action: 'register'}, {root: true}).then(() => {
      context.dispatch('SUBMIT_APPLICATION', {slug: payload.slug})
      context.dispatch('GET_PLAYER_SUBMISSIONS')
    })
  },
}

// mutations
const mutations = {
  setSubmitted(state, payload) {
    state.submitted = payload
  },
  addToSubmitted(state, payload) {
    state.submitted = [...state.submitted, payload]
  },
  setStatus(state, payload) {
    state.status = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
