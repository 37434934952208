<template lang="html">
  <div :class="['User', reversed ? 'User--reversed' : '']" v-if="user">
    <span class="User__name">{{ name }}</span>
    <img :src="user.photo_url" :alt="name" class="User__picture" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'User',
    props: {
      reversed: Boolean,
    },
    computed: {
      ...mapState({
        user: (state) => state.user.profile,
      }),
      name() {
        return this.user ? this.user.name : 'Loading...'
      },
    },
  }
</script>
