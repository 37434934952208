const GamesRoutes = [
  {
    path: '/games',
    name: 'games',
    component: () => import('@/views/Games/Games'),
    meta: {
      auth: true,
      subscriptionRequired: true,
    },
  },
  {
    path: '/games/:id',
    name: 'game',
    component: () => import('@/views/Games/SingleGame'),
    meta: {
      auth: true,
      subscriptionRequired: true,
      back: true,
      breadcrumbs: [
        {
          routeName: 'dashboard',
          name: 'Dashboard',
        },
        {
          routeName: 'game',
          name: 'Game',
        },
      ],
    },
  },
]

export default GamesRoutes
